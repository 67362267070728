import React, { useState } from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageUploadAndCrop = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: 'px', x:20, y:20, width: 550, height: 550 });
  const [cropper, setCropper] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);


  const getNewAvatarUrl = (e) => {
    if (e.target.files) {
        setSelectedFiles(URL.createObjectURL(e.target.files[0]));
    }
  };
  const getCropData = async () => {
    if (cropper) {
      const file = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });
      if (file) {
      }
    }
  };
  const cropImage = pixelCrop => {
    const selectedFile = selectedFiles[0];
    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    const croppedImageUrl = canvas.toDataURL('image/jpeg');
    setCroppedImage(croppedImageUrl);
  };

  return (
    <div>
      <h2>Upload and Crop Images</h2>
      <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={getNewAvatarUrl}
            multiple
        />
        <Cropper
        src={selectedFiles}
        style={{ height: 400, width: 400 }}
        initialAspectRatio={4 / 3}
        minCropBoxHeight={100}
        minCropBoxWidth={100}
        guides={false}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <button onClick={cropImage}>Crop Image</button>
    </div>
  );
};

export default ImageUploadAndCrop;
