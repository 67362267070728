import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export const JobCategory = () => {
    const [employerList,setEmployerList] = useState([]);
    const [catName,setCatName]=useState('');
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const { id } = useParams();
    const history = useHistory()
    useEffect( ()=> {
        if(auth){
            fetch(api_url+'/user/get_employee?category_id='+id
            )
            .then(response => response.json())
            .then(result => { setEmployerList(result); setCatName(result.employee[0].category.name) })
            .catch(error => console.log(error));
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <div className="sidebar job-sidebar clearfix" style={{marginBottom:'160px'}}>
                        <div className="container">
                            {/* row */}
                            <div className="row">
                                <div className="col-lg-12 content-area">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                <span className="showing-result">{catName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            employerList.employee && employerList.employee.length> 0 ?
                                            employerList.employee.map((item, index) => 
                                            <div className="col-lg-12 col-md-12">
                                                {/* featured-imagebox */}
                                                <div className="featured-imagebox featured-imagebox-employer">
                                                    <div className="featured-thumbnail">
                                                        <img src={api_url+item.profile} />
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>{item.name}</h3>
                                                        </div>
                                                        <div className="featured-bottom">
                                                            <div className="job-meta">
                                                                {item.customer_type === 'employer' ?<span>Profession Name :-   {item.profession_name}</span>:''}
                                                                {item.customer_type === 'employee' ? <span className='skills-list'>Skills:- {item.userSkills.map((item2, index) => (
                                                                    index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                                    ))} 
                                                                </span>
                                                                :''
                                                                }
                                                                <span>Rating<br></br>
                                                                    <div className="star-ratings">
                                                                        <ul className="rating">
                                                                            {[1, 2, 3, 4, 5].map((valuess) => (
                                                                            <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                            ))}
                                                                            <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                        </ul>
                                                                    </div>

                                                                </span>
                                                            </div>
                                                            {auth?(<>
                                                            <div className="view-block">
                                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                            </div>
                                                            </>):''}
                                                        </div>
                                                    </div>
                                                </div>{/* featured-imagebox end */}
                                            </div>
                                            )
                                            :<h2 className='text-danger text-center'>No Data Found!!</h2>
                                        }
                                    </div>{/* row end */}
                                </div>
                            </div>{/* row end */}
                        </div>
                    </div>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default JobCategory;