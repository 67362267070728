import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

 const UpdateProfile = () => {
    const [validated, setValidated] = useState(false)
    const [name,setName] = useState(null)
    const [email,setEmail] = useState(null)
    const [gender,setGender] = useState(null)
    const [dob,setDob]= useState(null)
    const [permanentAddress,setPermanentAddress] = useState(null)
    const [presentAddress,setPresentAddress] = useState(null)
    const [aadharNo,setAadharNo] = useState(null)
    const [education,setEducation] = useState(null)
    const [state,setState] = useState(null)
    const [district,setDistrict] = useState(null)
    const [city,setCity] = useState(null)
    const [alternatePhone,setAlternatePhone] = useState(null)
    const [stateList,setStateList]=useState([])
    const [districtList,setDistrictList]=useState([])

    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    const api_url=process.env.REACT_APP_API_URL
    useEffect( () => {
        if(auth){
            getStates();
            getProfile();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const getStates = async () => {
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        let result = await fetch(api_url+'get_state',{
          headers: {
            "Authorization": access_token,
            // Other headers if needed
          }
        })
        result = await result.json()
        setStateList(result)
    }
    const handleDistrict = async (stateid) => {
        let skills_array = stateid.split(",").map(item => item.trim());
        let statid=skills_array[0];
        setState(skills_array[1]);
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        let result = await fetch(api_url+'get_district?parent_id='+statid,{
          headers: {
            "Authorization": access_token,
            // Other headers if needed
          }
        })
        result = await result.json()
        setDistrictList(result)
    }
    const getProfile = async () => {
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        var result = await fetch(api_url+'user/get_profile', {
            method: 'get',
            headers: {
                "Authorization": access_token,
            }
        })
        result = await result.json()
        // console.log(result)
        setName(result.profile_details.name)
        setEmail(result.profile_details.email)
        setAlternatePhone(result.profile_details.alternate_phone)
        setGender(result.profile_details.gender)
        setAadharNo(result.profile_details.aadhar_number)
        setState(result.profile_details.state._id)
        setDistrict(result.profile_details.district._id)
        handleDistrict(result.profile_details.state.id+','+result.profile_details.state._id)
        setCity(result.profile_details.city)
        // Get year, month, and day from the date object
        const from_dateObject = new Date(result.profile_details.dob);
        const year1 = from_dateObject.getFullYear();
        const month1 = String(from_dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day1 = String(from_dateObject.getDate()).padStart(2, '0');

        // Create the formatted date string in "Y-m-d" format
        const fromdate = `${year1}-${month1}-${day1}`;
        setDob(fromdate)
        setPresentAddress(result.profile_details.present_address)
        setPermanentAddress(result.profile_details.permanent_address)
        setEducation(result.profile_details.education)
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((name !== null) || (email !== null) || (gender !== null) || (alternatePhone !== null) || (dob !== null) || (state !== null) || (district !== null) || (city !== null) || (education !== null) || (presentAddress !== null) || (permanentAddress !== null) || (alternatePhone.alternatePhone<10) || (alternatePhone.alternatePhone>10) || (aadharNo.length <12) || (aadharNo.length > 12) ){
            let raw = JSON.stringify({"name":name,"email":email,"alternate_phone":alternatePhone,"gender":gender,"education":education,"state_id":state,"district_id":district,"city":city,"present_address":presentAddress,"permanent_address":permanentAddress,"aadhar_number":aadharNo,"dob":dob});
            access_token=sessionStorage.getItem('user');
            access_token="Bearer "+access_token;
            const result=fetch(api_url + 'user/update_profile', {
                method: 'POST',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!')
                    history.push(process.env.PUBLIC_URL+'/job_plan')
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please fill all required fields!!');
        }
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Update Profile"
                        breadcrumb="Update Profile"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20" style={{background:'#c79292'}}>
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                            <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Name:</span>
                                                                            <input type="text" id="txtname" value={name} className='form-control' placeholder="Enter Your Name here..*" onChange={(e) => { setName(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-email"></i><span style={{fontWeight:'bold',color:'white'}}>Email:</span>
                                                                            <input type="email" id="txtemail" value={email} className='form-control' placeholder="Enter Your Email here..*" onChange={(e) => { setEmail(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Gender*:</span>
                                                                            <select id="txtgender" className='form-control' onChange={(e) => { setGender(e.target.value) }} required={true}>
                                                                                <option value="null">-- Select Gender --</option>
                                                                                <option value="Male" selected={gender === 'Male' ? true:false}>Male</option>
                                                                                <option value="Female" selected={gender === 'Female' ? true:false}>Female</option>
                                                                                <option value="Other" selected={gender === 'Other' ? true:false}>Other</option>
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>DOB*:</span>
                                                                            <input type="date" id="txtdate" className='form-control' value={dob} placeholder="Enter Your Date Of Birth here..*" onChange={(e) => { setDob(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-mobile"></i><span style={{fontWeight:'bold',color:'white'}}>Alternate Mobile Number:</span>
                                                                            <input type="number" id="txtaltnumber" value={alternatePhone} className='form-control' placeholder="Enter Your Alternate Mobile Number here..*" onChange={(e) => { setAlternatePhone(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Aadhar Number:</span>
                                                                            <input type="number" id="txtadharno" value={aadharNo} className='form-control' placeholder="Enter Your Aadhar Number here..*" onChange={(e) => { setAadharNo(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Education:</span>
                                                                            <textarea type="textarea" id="txteducation" className='form-control' value={education} placeholder="Enter Your Education Details here..*" onChange={(e) => { setEducation(e.target.value) }} required="">{education}</textarea>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>State*:</span>
                                                                            <select id="txtstate" className='form-control' onChange={(e) => { handleDistrict(e.target.value) }} required="">
                                                                                <option value="">-- Select State --</option>
                                                                                {stateList.states?.map((item1,index) => (
                                                                                    <option value={item1.id+','+item1._id} key={item1.name} selected={state === item1._id ? true:false}>
                                                                                    {item1.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>District*:</span>
                                                                            <select id="txtdistrict" className='form-control' onChange={(e) => { setDistrict(e.target.value) }} required="">
                                                                                <option value="">-- Select District --</option>
                                                                                {districtList.districts?.map((item1,index) => (
                                                                                    <option value={item1._id} key={item1.name} selected={district === item1._id ? true:false}>
                                                                                    {item1.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>City:</span>
                                                                            <input type="text" id="txtcity" value={city} className='form-control' placeholder="Enter Your City Name here..*" onChange={(e) => { setCity(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Permanent Address:</span>
                                                                            <textarea type="textarea"value={permanentAddress} id="txtpermaddr" className='form-control' placeholder="Enter Your Permanent Address here..*" onChange={(e) => { setPermanentAddress(e.target.value) }} required=""></textarea>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 col-lg-4">
                                                                        <label className="mb-0">
                                                                        <i className="ti ti-user"></i><span style={{fontWeight:'bold',color:'white'}}>Present Address:</span>
                                                                            <textarea type="textarea" value={presentAddress} id="txtpresaddr" className='form-control' placeholder="Enter Your Present Address here..*" onChange={(e) => { setPresentAddress(e.target.value) }} required=""></textarea>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={handleSubmit}>Register Now</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default UpdateProfile;