import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';

export const Category = () => {
    const [catList,setCatList]=useState([]);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    useEffect( ()=> {
        if(auth){
            fetchCategoryData();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const fetchCategoryData = async () => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            const response = await fetch(api_url+'get_category');
            const result = await response.json();
            setCatList(result.category);
        } catch (error) {
            console.error('Error fetching category:', error);
        }
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Job <span className="text-theme-SkinColor">Categories</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row row-equal-height mb_10">
                                {catList.map((item, index) => (
                                    <div className="col-lg-2 col-md-4 col-6">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content style1">
                                            <a href={process.env.PUBLIC_URL + '/job_category/'+item._id}>
                                                <div className="ttm-box-view-overlay">
                                                    {/* finance-hover */}
                                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/backblue.gif")' }}></div>
                                                        <div className="layer-content"></div>
                                                    </div>{/* finance-hover end */}
                                                </div>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                                        <img className="img-fluid" src={api_url+item.image} style={{width:'78px',height:'78px',borderRadius:'50%'}} />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    {/* <div className="featured-desc">
                                                        <p>85 Jobs<br/></p>
                                                    </div> */}
                                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark btn btn-md btn-primary p-2 text-white" 
                                                    href={process.env.PUBLIC_URL + '/job_category/'+item._id}>View</a>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))}
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default Category;