import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';


 const MemberProfile = () => {
    const [validated, setValidated] = useState(false)
    const [profile,setProfile] = useState([])
    const [rating,setRating] = useState([])
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [activeButton, setActiveButton] = useState(0);
    const [skillsget,setSkillsget]=useState([])

    const [desc,setDesc]=useState(null);
    const [date,setDate]=useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [serviceType, setServiceType] = useState(null); // Default to daily service
    var totamt=0;



    var access_token='';
    const { id } = useParams();
    const api_url=process.env.REACT_APP_API_URL
    useEffect( () => {
        if(auth){
            getProfile();
            getRatingReviews();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const NextArrow = (props) => (
        <div {...props} className="slick-arrow slick-next">
          Next
        </div>
    );
    
    const PrevArrow = (props) => (
        <div {...props} className="slick-arrow slick-prev">
            Prev
        </div>
    );


    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: Math.min(3, profile.profession_photo?.length),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, 
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 768, // Tablet breakpoint
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 992, // Laptop breakpoint
              settings: {
                slidesToShow: 3,
              },
            },
        ],
    };

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setModalIsOpen(false);
    };

    
    const getProfile = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_profile?user_id='+id, {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setProfile(result.profile_details);
                setSkillsget(result.profile_details.skills)
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL+'/');
            }
        })
    }

    const getRatingReviews = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_rating?user_id='+id, {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setRating(result.ratings);
            }
        })
    }

    const addToCart = () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const formData = new FormData()
        formData.append("to_user_id",id)
        const result=fetch(api_url + 'user/add_to_cart', {
            method: 'POST',
            headers: {
                "Authorization": access_token,
            },
            body: formData,
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                alert(result.message+' successfully!!');
            }else{
                alert(result.message+"!!")
            }
        })
    }

    const handleButtonClick = (buttonName,type) => {
        setActiveButton(buttonName);
        setServiceType(type);
    };


    const handleSkillSelection = (skill) => {
        // Check if the skill is already selected
        const isSkillSelected = selectedSkills.some(selectedSkill => selectedSkill.skills.name === skill.skills.name);
        
        // If the skill is not already selected, add it to the selectedSkills array
        if (!isSkillSelected) {
            setSelectedSkills([...selectedSkills, skill]);
            setSelectedSkill([...selectedSkill,skill.skills._id]);
        } else {
            // Skill is already selected, you can display a message or handle it as needed
            console.log('Skill is already selected',skill);
        }
    };

    const handleRemoveSkill = (index) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice(index, 1);
        setSelectedSkills(updatedSkills);
        const updatedSkill = [...selectedSkill];
        updatedSkill.splice(index, 1);
        setSelectedSkill(updatedSkill);
    };

    const getTotalAmount = () => {
        return selectedSkills.reduce((total, skill) => {
            return total + (serviceType === '6548892a34947a8be36164de' ? skill.daily_price : skill.monthly_price);
        }, 0);
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((id !== '') ){
            if((serviceType === null) && (date === null) && (desc === null)){
                alert('Please fill all required details');
            }else{
                access_token=sessionStorage.getItem('user')
                access_token="Bearer "+access_token;
                let raw = JSON.stringify({"to_user_id":id,"date":date,"skill":selectedSkill,"job_pref_id":serviceType,"desc":desc,"amount":totamt});

                const result=fetch(api_url + 'user/send_job_request', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": access_token,
                    },
                    body: raw,
                    redirect: "follow"
                })
                .then(response=>response.json())
                .then(result=> {
                    if(result.status === true){
                        alert(result.message+' successfully!!')
                        setShowModal(false);
                    }else{
                        alert(result.error+"!!")
                    }
                })
            }
        }else{
            alert('please select user!!');
        }
    }
    
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Views Profile"
                        breadcrumb="Member Profile"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <div className="card">
                                                                <div className="card_background_img"></div>
                                                                <div className="card_profile_img"><img src={profile.profile === null ? process.env.PUBLIC_URL+'/images/logo.png': process.env.REACT_APP_API_URL+'/'+profile.profile } style={{width:'150px',height:'150px'}}/></div>
                                                                <div className="user_details">
                                                                    <h5 className='text-center mt-2'>{profile.name}</h5>
                                                                    <p className='text-center mb-0'>Subscribed</p>
                                                                    <p className='text-center mt-0 mb-0' style={{color:'#000'}}>Rating</p>
                                                                    <div className="star-ratings text-center">
                                                                        <ul className="rating">
                                                                            {[1, 2, 3, 4, 5].map((valuess) => (
                                                                            <li className={valuess <= profile.avg_rating ? 'active':''}><i className={valuess <= profile.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                            ))}
                                                                            <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{profile.liked_by ? profile.liked_by.length : 0}</span></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-sm-12 col-md-6 col-lg-6 mx-auto'>
                                                                            <button className='btn btn-md text-white' style={{float:'right',background:'#ff8d00'}} onClick={handleShowModal}>Send Request</button>
                                                                        </div>
                                                                        <div className='col-sm-12 col-md-6 col-lg-6 mx-auto'>
                                                                            <button className='btn btn-md' style={{color:'#ff8d00',border:'1px solid #ff8d00'}} onClick={addToCart}>Add To Cart</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{height:'2px'}}></hr>
                                                                    <div className='row p-2'>
                                                                        <div className='col-12 col-md-12 col-lg-4 mx-auto'>
                                                                            <label className='mb-0'>Profession Name</label>
                                                                            <input type='text' className='form-control' readOnly={true} value={profile.profession_name}/>
                                                                        </div>
                                                                        <div className='col-12 col-md-12 col-lg-4 mx-auto'>
                                                                            <label className='mb-0'>Profession Description</label>
                                                                            <p>{profile.profession_description}</p>
                                                                        </div>
                                                                        <div className='col-12 col-md-12 col-lg-4 mx-auto'>
                                                                            <label className='mb-0'>Profession Experience</label>
                                                                            <p>{profile.profession_experience}</p>
                                                                        </div>
                                                                        <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                                                                            <label className='mb-0'>Profession Photos</label>
                                                                            <div>
                                                                            {profile.profession_photo?.length > 0 ? (<>
                                                                                <Slider {...settings} className=''>
                                                                                    {profile.profession_photo.map((item2, index) => (
                                                                                    <div className='sliderimg p-1' onClick={() => openModal(api_url+item2.photo)}>
                                                                                        <img src={api_url+item2.photo}/>
                                                                                    </div>
                                                                                    ))}
                                                                                    {/* Add more slides as needed */}
                                                                                </Slider>
                                                                                {/* <Modal
                                                                                    isOpen={modalIsOpen}
                                                                                    onRequestClose={closeModal}
                                                                                    contentLabel="Professional Photo"
                                                                                > */}
                                                                                    {selectedImage && (
                                                                                    <div className="image-section">
                                                                                        <button onClick={closeModal}>Close</button>
                                                                                        <img src={selectedImage} alt="Selected" />
                                                                                    </div>
                                                                                    )}
                                                                                {/* </Modal> */}
                                                                                </>
                                                                            ):''}
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        {profile.skills?.length > 0 ? (
                                                                            <div className='col-12 col-md-12 col-lg-12 mx-auto text-center mt-3'>
                                                                                <label className='mb-0' style={{ fontWeight: 'bold', color: '#000' }}>Skills</label>
                                                                                <div className='row'>
                                                                                {profile.skills.map((item2, index) => ( index === profile.skills?.length - 1 ? <>
                                                                                                
                                                                                    <div className='col-12 col-md-6 col-lg-4 mb-2'>
                                                                                        <div style={{ border: '2px solid #ff8d00', borderRadius: '5px' }} className='p-1'>
                                                                                            <span className='skills-list' style={{ fontWeight: 'bold', color: '#000' }}>
                                                                                                Skill Name: {item2.skills.name } 
                                                                                                
                                                                                            </span>
                                                                                            <p className='mb-0'>Daily Price - { "Rs. "+item2.monthly_price }</p>
                                                                                            <p className='mt-0'>Monthly Price - {"Rs. "+item2.daily_price }</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    </>
                                                                                    : <>     
                                                                                    <div className='col-12 col-md-6 col-lg-4 mb-2'>
                                                                                        <div style={{ border: '2px solid #ff8d00', borderRadius: '5px' }} className='p-1'>
                                                                                            <span className='skills-list' style={{ fontWeight: 'bold', color: '#000' }}>
                                                                                                Skill Name: {item2.skills.name}
                                                                                                
                                                                                            </span>
                                                                                            <p className='mb-0'>Daily Price - { "Rs. "+item2.monthly_price }</p>
                                                                                            <p className='mt-0'>Monthly Price - {"Rs. "+item2.daily_price }</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    </>
                                                                                ))}
                                                                                </div>
                                                                            </div>
                                                                            ) : (
                                                                                ''
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="card_count mt-5 mb-4">
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <p style={{fontSize:'14px',color:'#000',marginTop:'25px'}} className='text-center mb-0'>Reviews and Ratings</p>
                                                                            <hr style={{height:'3px'}} className='mt-0'></hr>
                                                                        </div>
                                                                        {rating?.length > 0 ? (
                                                                            rating.map((item3, index) => ( <>
                                                                        <div className='col-12 col-md-8 col-lg-8 p-4 pb-0 mx-auto'>
                                                                            <div className='card p-4' style={{background:'#ebebeb',color:'#000'}}>
                                                                                <div className='row'>
                                                                                
                                                                                    <div className='col-12 col-md-6 col-lg-6 text-center'>
                                                                                        <p className='mb-0' style={{fontWeight:'bold'}}>{item3.rate_by.name}</p>
                                                                                        <p>{item3.review}</p>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-6 col-lg-6 text-center'>
                                                                                        <p className='mb-0' style={{fontWeight:'bold',fontSize:'18px',color:'#ff8d00'}}>
                                                                                            {item3.rating === 5 ? 'Excellent' : ''}
                                                                                            {item3.rating === 4 ? 'Great' : ''}
                                                                                            {item3.rating === 3 ? 'Good' : ''}
                                                                                            {item3.rating === 2 ? 'Bad' : ''}
                                                                                            {item3.rating === 1 ? 'Terrible' : ''}
                                                                                        </p>
                                                                                        <div className="star-ratings text-center">
                                                                                            <ul className="rating">
                                                                                                {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                                <li className={valuess <= item3.rating ? 'active':''}><i className={valuess <=item3.rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </>
                                                                        ))
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modalcust">
                                <div className="modal-header">
                                    <h5 className="modal-title">Enter Job Description</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal}>X</button>
                                </div>
                                <div className="modal-body">
                                    <textarea className='form-control' placeholder='Enter job desc' onChange={(e) => { setDesc(e.target.value);} }></textarea>
                                    <label>Choose Date</label>
                                    <input type='date' value={moment().format("YYYY-MM-DD")} onChange={(e) => { setDate(e.target.value);} } className='form-control' />
                                    <label>Select Job Preference</label>
                                    <div className={`historyrec catp ${activeButton === 1 ? 'active' : ''}`} onClick={() => handleButtonClick(1,'6548892a34947a8be36164de')}>Daily Service</div>
                                    <div className={`historyrec catp ${activeButton === 2 ? 'active' : ''}`} onClick={() => handleButtonClick(2,'6548894034947a8be36164e2')}>Monthly Service</div>
                                    <label className='mt-2'>Select the skills which you want</label>
                                        <div className='row'>
                                            {selectedSkills.map((skill, index) => (
                                                <div key={index} className='col-md-2'>
                                                    <span className='mr-2 skillselected'>{skill.skills.name}</span>
                                                    <button onClick={() => handleRemoveSkill(index)} className='text-danger'>X</button>
                                                </div>
                                            ))}
                                        </div>
                                        <p>Total Amount: {  totamt= getTotalAmount() }</p>
                                        <div className='row'>
                                            {skillsget.map((item2, index) => ( index === skillsget?.length - 1 ? <>       
                                                <div className='col-12 col-md-6 col-lg-4 mb-2' onClick={() => handleSkillSelection(item2)}>
                                                    <div style={{ border: '2px solid #ff8d00', borderRadius: '5px' }} className='p-1'>
                                                        <span className='skills-list' style={{ fontWeight: 'bold', color: '#000' }}>
                                                            Skill Name: {item2.skills.name } 
                                                            
                                                        </span>
                                                        <p className='mb-0'>Daily Price - { "Rs. " +item2.daily_price}</p>
                                                        <p className='mt-0'>Monthly Price - {"Rs. " +item2.monthly_price}</p>
                                                    </div>
                                                </div>
                                                </>
                                                : <>  
                                                <div className='col-12 col-md-6 col-lg-4 mb-2' onClick={() => handleSkillSelection(item2)}>
                                                    <div style={{ border: '2px solid #ff8d00', borderRadius: '5px' }} className='p-1'>
                                                        <span className='skills-list' style={{ fontWeight: 'bold', color: '#000' }}>
                                                            Skill Name: {item2.skills.name } 
                                                            
                                                        </span>
                                                        <p className='mb-0'>Daily Price - { "Rs. " +item2.daily_price}</p>
                                                        <p className='mt-0'>Monthly Price - {"Rs. " +item2.monthly_price}</p>
                                                    </div>
                                                </div>
                                                </>
                                            ))}
                                        </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn text-white" style={{backgroundColor:'#ff8d00'}} onClick={handleSubmit}>
                                        Send Request
                                    </button>
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Modal */}
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default MemberProfile;