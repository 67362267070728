import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

 const ChooseJobPlan = () => {
    const [validated, setValidated] = useState(false)
    const [regCharge,setRegCharge] = useState([])
    const [regId,setRegId] = useState(null)
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    useEffect( () => {
        if(auth){
            getRegCharge();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    
    const getRegCharge = async () => {
        const api_url=process.env.REACT_APP_API_URL
        const response = await fetch(api_url+'get_registration_charge');
        const result = await response.json()
        setRegCharge(result.registration_charge);
    }
    const onSKip = () => {
        history.push(process.env.PUBLIC_URL+"/");
    }
    const paynow = () => {
        history.push(process.env.PUBLIC_URL+"pay/"+regId);
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Choose Job Plan"
                        breadcrumb="Job Plan"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2" style={{backgroundImage:`url('images/web/plan_header.png')`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <div style={{textAlign:'center'}}>
                                                                <img src={process.env.PUBLIC_URL+'/images/web/job.png'} style={{height:'100px'}}/>
                                                                <h5 className='text-danger m-2'>JOBS ANYWHERE</h5>
                                                                <p>If you want to do work anywhere that you want, We will help to find your dream jobs easily</p>
                                                            </div>
                                                               
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className='col-12'>
                                                                        <p>Choose your plan below</p>
                                                                    </div>
                                                                    {regCharge && regCharge.length >0 ? (
                                                                         regCharge?.map((item, index) => ( <>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className={`w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white ${regId === item._id ? 'planbtn' : ''}`} 
                                                                             onClick={ () => { setRegId(item._id); }} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>{item.year} year</span>
                                                                                <span className='btn btn-sm bg-danger' style={{float:'right'}}>&#8377; {item.price}/-</span>
                                                                             </div>
                                                                             <span>Renewal Charge <span style={{color:'#ff973a'}}>&#8377; {item.renewal_price}/-</span></span>
                                                                        </label>
                                                                    </div>
                                                                    </>
                                                                    ))):''}
                                                                    {/* <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className="w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white"
                                                                             onClick={(e) => setLanguage(e.target.value)} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>2 year</span>
                                                                                <span className='btn btn-sm bg-danger' style={{float:'right'}}>&#8377; 450/-</span>
                                                                             </div>
                                                                             <span>Renewal Charge <span style={{color:'#ff973a'}}>&#8377; 200/-</span></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className="w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white"
                                                                             onClick={(e) => setLanguage(e.target.value)} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>5 year</span>
                                                                                <span className='btn btn-sm bg-danger' style={{float:'right'}}>&#8377; 1000/-</span>
                                                                             </div>
                                                                             <span>Renewal Charge <span style={{color:'#ff973a'}}>&#8377; 500/-</span></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className="w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white"
                                                                             onClick={(e) => setLanguage(e.target.value)} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>6 year</span>
                                                                                <span className='btn btn-sm bg-danger' style={{float:'right'}}>&#8377; 1200/-</span>
                                                                             </div>
                                                                             <span>Renewal Charge <span style={{color:'#ff973a'}}>&#8377; 600/-</span></span>
                                                                        </label>
                                                                    </div> */}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={paynow}>PAY NOW</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="w-100 btn btn-sm ttm-btn-size-sm ttm-btn-shape-rounded" type="button" onClick={onSKip}>SKIP</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-12 mx-auto">
                                                                        <label className="mb-0">
                                                                            <p><span style={{color:'#ff973a'}}>Note:-</span>After the plan expires, you are recruited to pay the renewal charge based on the selected plan to continue accessing the services of this app.</p>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default ChooseJobPlan;