import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

 const ResetPassword = () => {
    const [validated, setValidated] = useState(false)
    const [email, setEmail] = React.useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    useEffect( () => {
        if(auth){
            history.push(process.env.PUBLIC_URL+'/');
        }
    },[]);
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((email !== '') ){
            const formData = new FormData()
            formData.append("email",email)
            const result=fetch(api_url + 'user/send_reset_password_mail', {
                method: 'POST',
                body: formData,
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    sessionStorage.setItem('fps',email);
                    alert(result.message+'!!')
                    history.push(process.env.PUBLIC_URL+'/verify_otp')
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please enter email!!');
        }
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Forgot Password?"
                        breadcrumb="Reset Password"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <div style={{textAlign:'center'}}>
                                                            <img src={process.env.PUBLIC_URL+'/images/web/forgp.png'} style={{height:'250px'}}/>
                                                            </div>
                                                               
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <label>
                                                                            <i className="ti ti-email"></i>
                                                                            <input type="text" id="txtemail" onChange={(e)=>{ setEmail(e.target.value);}} placeholder="Enter your registered Email/Phone here" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={handleSubmit}>NEXT</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default ResetPassword;