import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {BottomFooter} from '../components/layout/BottomFooter';
import { PaginationItem, PaginationLink,} from 'react'
import { useHistory, useParams } from 'react-router-dom';


export const FilterD = () => {
    const [employerList,setEmployerList] = useState([]);
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const api_url=process.env.REACT_APP_API_URL
    const { id } = useParams();
    useEffect( () => {
        if(auth){
            fetch(api_url+'/user/search?query_for='+id
            )
            .then(response => response.json())
            .then(result => { setEmployerList(result); })
            .catch(error => console.log(error));
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const [activePage, setActivePage] = useState(1);
  const totalItems = employerList.users ? employerList.users.length : 0; // Total items in your list
  const itemsPerPage = 10; // Items per page

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employerList.users ? employerList.users.slice(indexOfFirstItem, indexOfLastItem):0;

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    setActivePage(page);
    // You can perform actions here when a page is clicked, like fetching data for that page
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={activePage === i}>
          <PaginationLink onClick={() => handlePageClick(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return paginationItems;
  };
    
    return (
        <>
            <div>
                <SideNavbar />
                <div style={{ display: 'flex' }}>
                    <Container>
                        {/* <Typography variant="h4">Welcome to your Dashboard!</Typography> */}
                        <div className="sidebar job-sidebar clearfix" style={{marginBottom:'120px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">All Search Results:</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        {
                                            employerList.users && employerList.users.length> 0 ?
                                            currentItems.map((item, index) => 
                                            <div className="col-lg-12 col-md-12">
                                                {/* featured-imagebox */}
                                                <div className="featured-imagebox featured-imagebox-employer">
                                                    <div className="featured-thumbnail">
                                                        <img src={api_url+item.profile} />
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>{item.name}</h3>
                                                        </div>
                                                        <div className="featured-bottom">
                                                            <div className="job-meta">
                                                                {item.customer_type === 'employer' ?<span>Profession Name :-   {item.profession_name}</span>:''}
                                                                {item.customer_type === 'employee' ? <span className='skills-list'>Skills:- {item.userSkills.map((item2, index) => (
                                                                    index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                                    ))} 
                                                                </span>
                                                                :''
                                                                }
                                                                <span>Rating<br></br>
                                                                    <div className="star-ratings">
                                                                        <ul className="rating">
                                                                            {[1, 2, 3, 4, 5].map((valuess) => (
                                                                            <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                            ))}
                                                                            <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                        </ul>
                                                                    </div>

                                                                </span>
                                                            </div>
                                                            {auth?(<>
                                                            <div className="view-block">
                                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                            </div>
                                                            </>):''}
                                                        </div>
                                                    </div>
                                                </div>{/* featured-imagebox end */}
                                            </div>
                                            )
                                            :''
                                        }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default FilterD;