import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export const JobRequests = () => {
    const [jobList,setJobList]=useState([]);
    const [btnType,setBtnType]=useState(1)
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token='';
    useEffect(() => {
        if (auth) {
            fetchAllJob(1);
        } else {
            history.push(process.env.PUBLIC_URL + '/login');
        }
    }, []);

    const fetchAllJob = async (type) => {
        try {
            access_token = "Bearer " + auth;
            let apiUrl = '';
            if (type === 1) {
                apiUrl = api_url + '/user/get_job_request';
            } else if (type === 2) {
                apiUrl = api_url + '/user/get_job_request?type=daily_service';
            } else if (type === 3) {
                apiUrl = api_url + '/user/get_job_request?type=monthly_job';
            }
            const response = await fetch(apiUrl, {
                headers: {
                    "Authorization": access_token,
                },
            });
            const result = await response.json();
            setJobList(result);
            setBtnType(type);
        } catch (error) {
            console.error('Error fetching Requests:', error);
        }
    };

    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Job <span className="text-theme-SkinColor">Requests</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                        <div className='text-center'>
                                            <button className={'btn btn-md' + (btnType === 1 ? ' bg-warning text-white' : 'bg-light text-dark')} onClick={() => fetchAllJob(1)}>All</button>
                                            <button className={'btn btn-md' + (btnType === 2 ? ' bg-warning text-white' : 'bg-light text-dark')} onClick={ () => fetchAllJob(2)}>Daily</button>
                                            <button className={'btn btn-md' + (btnType === 3 ? ' bg-warning text-white' : 'bg-light text-dark')} onClick={ () => fetchAllJob(3)}>Monthly</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 mx-auto mt-3">
                                        <div className="ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                            <div className="layer-content">
                                                <div className="ttm-tabs ttm-tab-style-02">
                                                    <Tabs>
                                                        <TabList className="tabs">
                                                            <Tab className="tab">
                                                            </Tab>
                                                        </TabList> 
                                                        <div className="content-tab">
                                                            <TabPanel>
                                                                <div className="card p-3" style={{border: '2px solid #ffc107'}}>
                                                                   <div className='row'>
                                                                        <div className='col-6'>
                                                                            <img style={{width:'78px',height:'78px',borderRadius:'50%',border: '2px solid #ffc107'}} src={process.env.PUBLIC_URL+'/images/logo.png'}/>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <a href='' className='text-danger'>Download Receipt</a>
                                                                        </div>
                                                                        <div className='col-6 mt-2'>
                                                                            <p>From</p>
                                                                        </div>
                                                                        <div className='col-6 text-right mt-1'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>To</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Profession Name</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Profession Skill</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Job Type</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                   </div>
                                                                   <hr></hr>
                                                                   <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p>Job Request Send Date</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Working Date</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Work Start Date</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Work Complete Date</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                   </div>
                                                                   <hr></hr>
                                                                   <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p>Gross Monthly Salary</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Convenience Fee(2%)</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <p>Net Monthly Salary</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                   </div>
                                                                   <hr></hr>
                                                                   <hr></hr>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p>Request Status</p>
                                                                        </div>
                                                                        <div className='col-6 text-right'>
                                                                            <p><b>kjshdjf</b></p>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <p>Job Description</p>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <textarea style={{width:'100%'}} value="Job Description...." readOnly></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <hr></hr>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <button className='btn btn-sm btn-primary'>View Work Details</button>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <button className='btn btn-sm btn-primary'>Write a Review</button>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <button className='btn btn-sm btn-primary'>Add Like</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </div>       
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default JobRequests;