import React,{useEffect, useState} from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom'



export const Otp = () => {
    const [validated, setValidated] = useState(false)
    const [otp, setOtp] = React.useState('')
    const [phone,setPhone] = React.useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    useEffect( () => {
        document.title='Nana | OTP'
        if(auth){
            history.push(process.env.PUBLIC_URL+'/');
        }
    });
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((otp !== '') && (phone !== '') ){
           
            let raw = JSON.stringify({"otp":otp,"phone":phone});

            const result=fetch(api_url + 'user/verify_otp', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    sessionStorage.setItem('user',result.access_token)
                    alert(result.message+' successfully!!')
                    history.push(process.env.PUBLIC_URL+'/congrats')
                }else{
                    alert(result.error+"!!")
                }
            })
        }
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="register"
                        breadcrumb="register"
                    />
                    {/* PageHeader end */}


                    {/* register */}
                    <div className="ttm-row register-section clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="text-center mb-20">
                                                <h3>OTP Verification</h3>
                                            </div>
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <a>
                                                            <i className="flaticon flaticon-job-search"></i>
                                                                <h5>OTP Verification</h5>
                                                            </a>
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">                                
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-mobile"></i>
                                                                            <input type="number" id="txtphone" placeholder="Enter Registered Mobile Number here..*" onChange={(e) => { setPhone(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-mobile"></i>
                                                                            <input type="number" id="txtotp" placeholder="Enter OTP here..*" onChange={(e) => { setOtp(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                            type="button" onClick={handleSubmit}>NEXT</button>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 mx-auto">
                                                                        <div className="mt-15">
                                                                            <p>Already have an account?<a href={process.env.PUBLIC_URL + '/login'} className="text-theme-SkinColor"> Sign in here</a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* login end */}

                    
                <Footer/>
                            
                </div>
            </>
          ) 
      }

export default Otp;