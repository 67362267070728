import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';


export const Banner2 = () => {
    const [show,setShow]=useState(false)
    const [search,setSearch]=useState('')
    const [bannerList,setBannerList]=useState([])
    var slick_slider = {
      dots: false,
      arrow: true,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        }
    },
    {
  
        breakpoint: 1024,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        }
    },
    {
  
        breakpoint: 680,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 575,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
    };
    const api_url=process.env.REACT_APP_API_URL

    useEffect( () => {
      getBanner();
    },[]);

    const history = useHistory()

    const onHandleSearch = () => {
      history.push(process.env.PUBLIC_URL+'/search/'+search)
    }

    const getBanner = async () => {
      const api_url=process.env.REACT_APP_API_URL
      let result = await fetch(api_url+'super_admin/get_banner')
      result = await result.json()
      setBannerList(result.banner);
    }
            
    return (
            
        <Slider className="slick_slider banner_slider banner_slider_1" {...slick_slider} slidesToShow={1} autoplay={true} >
          {
            bannerList && bannerList.length> 0 ?
            bannerList.map((item, index) =>
          <div className="slide">
            <div className="slide_img">
              <img className="img-fluid slide_img" src={api_url+item.image} />
            </div>
          </div>  
            )
            :''
          }           
        </Slider>
        
    )
}

export default Banner2;