import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents  } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';

export const Promotion = () => {
    const [validated, setValidated] = useState(false)
    const [stateList,setStateList]=useState([])
    const [districtList,setDistrictList]=useState([])
    const [isStateVisible, setIsStateVisible] = useState(true);
    const [isDistrictVisible, setIsDistrictVisible] = useState(false);
    const [isLocationVisible, setIsLocationVisible] = useState(false);
    const [paysec,setPaysec]=useState(0);
    const [title,setTitle]=useState(null)
    const [desc,setDesc]=useState(null)
    const [from_date,setFrom_date]=useState(moment().format("YYYY-MM-DD"))
    const [to_date,setTo_date]=useState(moment().format("YYYY-MM-DD"))
    const [payment_amount,setPayment_amount]=useState(2000)
    const [payment_mode,setPayment_mode]=useState('upi')
    const [payment_status,setPayment_status]=useState('success')
    const [promotion_type,setpromotion_type]=useState('state')
    const [state,setState]=useState(null)
    const [district,setDistrict]=useState(null)
    const [latitude,setLatitude]=useState(0)
    const [longitude,setLongitude]=useState(0)
    const [promotion_images,setPromotion_images]=useState([])
    const [promotion_video,setPromotion_video]=useState(null)
    const [perday,setPerday]=useState(1)

    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            getStates();
            calcdatdiff();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    
    const getStates = async () => {
        access_token="Bearer "+auth;
        let result = await fetch(api_url+'get_state',{
          headers: {
            "Authorization": access_token,
            // Other headers if needed
          }
        })
        result = await result.json()
        setStateList(result)
    }
    const onHandlePromotionType = (e) => {
        const type=e.target.value
        setpromotion_type(type);
        if(type === 'state'){
            setIsStateVisible(true);
            setIsDistrictVisible(false);
            setIsLocationVisible(false);
            setDistrict(null);
            setLatitude(0);
            setLongitude(0);
        }else if(type === 'district'){
            setIsStateVisible(true);
            setIsDistrictVisible(true);
            setIsLocationVisible(false);
            setLatitude(0);
            setLongitude(0);
        }else if(type === 'location'){
            setIsLocationVisible(true);
            setIsStateVisible(false);
            setIsDistrictVisible(false);
            setDistrict(null);
            setState(null);
        }else{
            setIsStateVisible(false);
            setIsDistrictVisible(false);
            setIsLocationVisible(false);
            setState(null);
            setDistrict(null);
            setLatitude(0);
            setLongitude(0);
        }
    }
    const handleDistrict = async (stateid) => {
        let skills_array = stateid.split(",").map(item => item.trim());
        let statid=skills_array[0];
        setState(skills_array[1]);
        access_token="Bearer "+auth;
        let result = await fetch(api_url+'get_district?parent_id='+statid,{
          headers: {
            "Authorization": access_token,
            // Other headers if needed
          }
        })
        result = await result.json()
        setDistrictList(result)
    }
    const calcdatdiff1 = async (date) => {
        setFrom_date(date);
        const differenceInTime = new Date(to_date).getTime() - new Date(date).getTime();
        const daydiff = differenceInTime / (1000 * 3600 * 24); 
        setPerday(daydiff);
        setPayment_amount((1+daydiff)*2000);
    }
    const calcdatdiff2 = async (date) => {
        setTo_date(date);
        const differenceInTime = new Date(date).getTime() - new Date(from_date).getTime();
        const daydiff = differenceInTime / (1000 * 3600 * 24); 
        setPerday(daydiff);
        setPayment_amount((1+daydiff)*2000);
    }
    const calcdatdiff = async () => {
        const differenceInTime = new Date(to_date).getTime() - new Date(from_date).getTime();
        const daydiff = differenceInTime / (1000 * 3600 * 24); 
        setPerday(daydiff);
        setPayment_amount((perday)*2000);
    }

    const initialIndiaLocation = { latitude: 20.5937, longitude: 78.9629 };
    const [selectedLocation, setSelectedLocation] = useState(initialIndiaLocation);

    const handleMapClick = (event) => {
        const { lat, lng } = event.latlng;
        setSelectedLocation({ latitude: lat, longitude: lng });
        setLatitude(lat);
        setLongitude(lng);
    };
    const markerIcon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
    });
    const MapClickHandler = () => {
        useMapEvents({
            click: (event) => {
                handleMapClick(event);
            },
        });

        return null;
    };

    const prmotionsubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        const formData = new FormData()
        formData.append('title', title)
        formData.append('desc', desc)
        formData.append('from_date', from_date)
        formData.append('to_date', to_date)
        formData.append('payment_amount', payment_amount)
        formData.append('payment_mode', payment_mode)
        formData.append('payment_status', payment_status)
        formData.append('promotion_type', promotion_type)
        formData.append('state', state)
        formData.append('district', district)
        formData.append('latitude', latitude)
        formData.append('longitude', longitude)
        formData.append('promotion_images', promotion_images)
        formData.append('promotion_video', promotion_video)

        access_token="Bearer "+auth;
        const result=fetch(api_url + 'user/purchase_promotion', {
            method: 'post',
            headers: {
                "Authorization": access_token,
                // Other headers if needed
            },
            body: formData,
        })
        .then(response=>response.json())
        .then(result=>{
            if(result.status == true){
                alert(result.message+' successfully!!')
                history.push(process.env.PUBLIC_URL + '/all_promotion');
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL + '/promotion');
            }
        });
    }
    const handleSubmit = (event) => {
        // console.log(formData)
        if((title !== null) && (desc !== null) && (payment_amount !== 0)){
            if(promotion_type === 'state'){
                if(state === null){
                    alert('Please select state!');
                }else{
                    prmotionsubmit(event);
                }
            }else if(promotion_type === 'district'){
                if(state === null){
                    alert('Please select state!');
                }
                else if(district === null){
                    alert('Please Select District!');
                }else{
                    prmotionsubmit(event);
                }
            }else if(promotion_type === 'location'){
                if((latitude === 0) || (longitude === 0)){
                    alert('Please pick location!');
                }else{
                    prmotionsubmit(event);
                }
            }
        }else{
            alert('Please fill all required fields!!');
        }
    }
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2><span className="text-theme-SkinColor">Promote</span> for your Profile</h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        <div className='card mb-4 p-2' style={{backgroundColor:'rgb(221 221 221 / 52%)'}}>
                                            <p><span className="text-theme-SkinColor">Note - </span> 1. Promotion Per/Day charge is <span className="text-theme-SkinColor">Rs. 2000 </span></p>
                                        </div>
                                        {/* featured-icon-box */}
                                        <div className='card mb-3' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'1px solid #f57c00'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <input type='text' className='form-control' onChange={ (e) => { setTitle(e.target.value) }} placeholder='Enter title here'/>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <textarea className='form-control' onChange={ (e) => { setDesc(e.target.value) }} placeholder='Enter Description here'/>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>From Date</label>
                                                        <input type='date' value={from_date} onChange={ (e) => { calcdatdiff1(e.target.value); }} className='form-control' />
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>To Date</label>
                                                        <input type='date' value={to_date} onChange={ (e) => { calcdatdiff2(e.target.value); }} className='form-control' />
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>Select Area for Promotion</label>
                                                        <div className='row'>
                                                            <div className='col-6'><input type='radio' value="state" onChange={ (e) => { onHandlePromotionType(e) }} name="area_promotion" checked={promotion_type === 'state'?true:false}/> State</div>
                                                            <div className='col-6'><input type='radio' value="location" onChange={ (e) => { onHandlePromotionType(e) }} name="area_promotion" checked={promotion_type === 'location'?true:false} /> Location</div>
                                                            <div className='col-6 mt-1'><input type='radio' value="district"  onChange={ (e) => { onHandlePromotionType(e) }} name="area_promotion" checked={promotion_type === 'district'?true:false} /> District</div>
                                                            <div className='col-6 mt-1'><input type='radio' value="pan_india" onChange={ (e) => { onHandlePromotionType(e) }} name="area_promotion" checked={promotion_type === 'pan_india'?true:false} /> Pan India</div>
                                                        </div>
                                                    </div>
                                                    {isStateVisible && (
                                                    <div className='col-12 mt-2'>
                                                        <label>Select District</label>
                                                        <select className='form-control' style={{border:'2px solid #f57c00',color:'#f57c00'}} onChange={ (e) => { handleDistrict(e.target.value); }}>
                                                            <option value="">Select Your State</option>
                                                            {stateList.states?.map((item1,index) => (
                                                                <option value={item1.id+','+item1._id} key={item1.name}>
                                                                {item1.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    )}
                                                    {isDistrictVisible && (
                                                        <div className='col-12 mt-2'>
                                                            <label>Select State</label>
                                                            <select className='form-control' style={{border:'2px solid #f57c00',color:'#f57c00'}} onChange={ (e) => { setDistrict(e.target.value) }}>
                                                                <option value="">Select Your District</option>
                                                                {districtList.districts?.map((item1,index) => (
                                                                    <option value={item1._id} key={item1.name}>
                                                                    {item1.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                    {isLocationVisible && (
                                                        <div className='col-12 mt-2' style={{zIndex:'1'}}>
                                                            <label htmlFor="locationmap">Location Picker</label>
                                                            <MapContainer
                                                                center={[20.5937, 78.9629]} // Initial center around india
                                                                zoom={5} // Initial zoom level
                                                                style={{ height: '400px', width: '100%' }}
                                                            >
                                                                <TileLayer
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                />

                                                                <MapClickHandler />
                                                                {selectedLocation && (
                                                                <Marker position={[selectedLocation.latitude, selectedLocation.longitude]} icon={markerIcon}>
                                                                    <Popup>
                                                                    Latitude: {selectedLocation.latitude} <br />
                                                                    Longitude: {selectedLocation.longitude}
                                                                    </Popup>
                                                                </Marker>
                                                                )}
                                                            </MapContainer>
                                                        </div>
                                                    )}
                                                    {/* <div className='col-12 mt-2'>
                                                        <p style={{fontSize:'14px'}}><span style={{color:'#ff8d00',fontWeight:'bold'}}>Note - </span>This promotion will visible on radius of 200 km of this area <span style={{color:'#000',fontWeight:'bold'}}>Location here..</span></p>
                                                    </div> */}
                                                    <div className='col-12 mt-2'>
                                                        <div>
                                                            <label htmlFor="input-file" className='form-control' style={{border:'2px solid #f57c00',color:'#f57c00'}}>Pick Images</label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            id="input-file"
                                                            accept=".jpg, .jpeg, .png"
                                                            style={{ display: "none" }}
                                                            onChange={ (e) => { setPromotion_images(e.target.files[0]) }}
                                                        />
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <div>
                                                            <label htmlFor="input-file2" className='form-control' style={{border:'2px solid #f57c00',color:'#f57c00'}}>Pick Video</label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            id="input-file2"
                                                            accept=".mp4"
                                                            style={{ display: "none" }}
                                                            onChange={ (e) => { setPromotion_video(e.target.files[0]) }}
                                                        />
                                                    </div>
                                                    <div className='col-4 mt-2'>
                                                        <button type="button" className='btn btn-sm text-white' style={{backgroundColor:'#f57c00'}} onClick={ () => { setPaysec(1); }}>Get Price</button>
                                                    </div>
                                                    {paysec === 1 ? (<>
                                                    <div className='col-12 mt-2'>
                                                        <p style={{fontSize:'14px'}}><span style={{color:'#ff8d00',fontWeight:'bold'}}>Alert - </span>For date  <span style={{color:'#000',fontWeight:'bold'}}>{moment(from_date).format('ddd MMM DD YYYY')}</span> to <span style={{color:'#000',fontWeight:'bold'}}>{moment(to_date).format('ddd MMM DD YYYY')}</span> promotion charge will be <span style={{color:'#ff8d00',fontWeight:'bold'}}>Rs.{payment_amount}</span></p>
                                                    </div>
                                                    <div className='col-4 mt-2'>
                                                        <button type="button" className='btn btn-sm text-white' style={{backgroundColor:'#f57c00'}} onClick={handleSubmit}>Pay Now {payment_amount}</button>
                                                    </div>
                                                    </>):''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default Promotion;