import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';


export const Banner = () => {
    const [show,setShow]=useState(false)
    const [search,setSearch]=useState('')
    const [bannerList,setBannerList]=useState([])
    var slick_slider = {
      dots: false,
      arrow: true,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        }
    },
    {
  
        breakpoint: 1024,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        }
    },
    {
  
        breakpoint: 680,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 575,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
    };
    const api_url=process.env.REACT_APP_API_URL

    useEffect( () => {
      getBanner();
    },[]);

    const history = useHistory()

    const onHandleSearch = () => {
      history.push(process.env.PUBLIC_URL+'/search/'+search)
    }

    const getBanner = async () => {
      const api_url=process.env.REACT_APP_API_URL
      let result = await fetch(api_url+'super_admin/get_banner')
      result = await result.json()
      setBannerList(result.banner);
    }
            
    return (
            
        <Slider className="slick_slider banner_slider banner_slider_1" {...slick_slider} slidesToShow={1} autoplay={true} >
          {
            bannerList && bannerList.length> 0 ?
            bannerList.map((item, index) =>
          <div className="slide">
            <div className="slide_img">
              <img className="img-fluid slide_img" src={api_url+item.image} />
            </div>
            <div className="slide__content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slide__content--headings ttm-textcolor-white text-center">
                      {/* <h3  data-animation="animate__animated animate__bounce animate__delay-2s">Empowering, Inspiring, Rising.</h3>
                      <div className="d-flex align-items-center justify-content-center">
                        <h2  data-animation="animate__fadeInDown">Jobs & Services Agency</h2>
                      </div> */}
                      <div className="mt-30">
                        <div className="row">
                          <div className="col-lg-12">
                            <p  data-animation="animate__fadeInDown" className="mb-30 mb-md-0">
                                <form id="b_search_Form" className="b_search_Form wrap-form d-block" method="post" action="#" data-mailchimp="true">
                                  <div className="row row-equal-height">
                                    <div className="col-lg-6">
                                      <label>
                                          <i className="ti ti-search"></i>
                                          <input type="text" id="filter" onChange={ (e) => setSearch(e.target.value) } placeholder="Job Title or username or city name or skill name or category name"/>
                                      </label>
                                    </div>
                                    <div className="col-lg-3">
                                      <label>
                                          <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                          ttm-btn-color-skincolor" type="button" onClick={onHandleSearch}>Find Jobs</button>
                                      </label>
                                    </div>
                                  </div>
                                </form>
                            </p>
                            <p  data-animation="animate__fadeInDown" className="d-none d-md-block">
                              <span className="text-theme-WhiteColor">Popular Searches :  </span>Designer,  Developer,  Web,  IOS,  PHP,  Senior,  Engineer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
            )
            :''
          }           
        </Slider>
        
    )
}

export default Banner;