import React, { Component,useState } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import ProgressBar from 'react-animated-progress-bar';


export class Home extends Component {
    
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
      jobPreferences : [],
      catList: [],
      error: null,
      api_url:process.env.REACT_APP_API_URL
    };
  }

  componentDidMount() {
    this.fetchCategoryData();
    this.getJobPreferences();
  }

  fetchCategoryData = async () => {
    try {
        const api_url=process.env.REACT_APP_API_URL
      const response = await fetch(api_url+'get_category');
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
      const result = await response.json();
      this.setState({ catList: result.category });
    } catch (error) {
      console.error('Error fetching category:', error);
      this.setState({ error: error.message });
    }
  };

    getJobPreferences = async () => {
        try{
            const api_url=process.env.REACT_APP_API_URL
            const response = await fetch(api_url+'get_job_preference');
            const result = await response.json()
            this.setState({ jobPreferences: result.job_preferences });
        }catch(error){
            console.error('Error fetching Job Service:', error);
            this.setState({ error: error.message });
        }
    };
  
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
    
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
      const { jobPreferences,catList, error } = this.state;
      const api_url=process.env.REACT_APP_API_URL
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* Banner */} 
            <Banner/>
            {/* Banner end */} 
            
            
            {/* features-section */}
            <section className="ttm-row features-section clearfix" style={{background:'#b9afaf4d'}}>
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Get <span className="text-theme-SkinColor">Appropriate</span></h3>
                                    <h2 className="title">Job Categories</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row row-equal-height mb_10">
                    {catList.map((item, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <a href={process.env.PUBLIC_URL + '/cat_result/'+item._id}>
                                    <div className="ttm-box-view-overlay">
                                        {/* finance-hover */}
                                        <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/backblue.gif")' }}></div>
                                            <div className="layer-content"></div>
                                        </div>{/* finance-hover end */}
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src={api_url+item.image} style={{width:'78px',height:'78px',borderRadius:'50%'}} />
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>{item.name}</h3>
                                        </div>
                                        {/* <div className="featured-desc">
                                            <p>85 Jobs<br/></p>
                                        </div> */}
                                        <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark btn btn-md btn-primary p-2 text-white" 
                                        href={process.env.PUBLIC_URL + '/cat_result/'+item._id}>View</a>
                                    </div>
                                </a>
                            </div>{/* featured-icon-box end */}
                        </div>
                    ))}
                    </div>{/* row end */}
                </div>
            </section>
            {/* features-section end */}

            {/* download-about-section */}
            <section className="ttm-row about-section bg-img5 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("images/2.png")' }}>
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                <div className="container">
                    {/* row */} 
                    <div className="row">
                        <div className="col-lg-6">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h2 className="title">Download & Enjoy</h2>
                                </div>
                                <div className="title-desc">
                                    <p>Search, find and apply for jobs directly on your mobile device or desktop Manage all of the jobs you have
                                         applied to from a convenience secure dashboard.</p>
                                </div>
                            </div>{/* section title end */}
                            <div className="d-sm-flex social-btns">
                                <a className="app-btn" href="https://www.apple.com/">
                                    <i className="fab fa-apple"></i>
                                    <p>Available on the <br/> <span className="big-txt">App Store</span></p>
                                </a>

                                <a className="app-btn" href="https://play.google.com/">
                                    <i className="fab fa-google-play"></i>
                                    <p>Get it on <br/> <span className="big-txt">Google Play</span></p>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-10 mx-auto">
                            <div className="ttm_single_image-wrapper mt_20 p-15 position-absolute mb_lg-200 d-lg-block d-none">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/mobile-app.webp'} alt="single_04" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            {/* about-section end */}

            {/* services-section */}
            <section className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"  style={{ backgroundImage: 'url(images/bg-image/row-bgimage-1.png)' }}>
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-11">
                            {/* section title */}
                            <div className="section-title style2 mb-0">
                                <div className="title-header">
                                    <h3>Classic <span className="text-theme-SkinColor">Service</span></h3>
                                    <h2 className="title">We Understand Needs</h2>
                                </div>
                                <div className="title-desc">
                                    <p>A process that involves everything from <span className="text-theme-SkinColor">identifying,</span> attracting, shortlisting, interviewing, selecting & hiring employees.</p>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* Slider */}
                    <Slider className="row slick_slider slick-arrows-style2 pt-20 mb_10" {...slick_slider} slidesToShow={2} rows={2} 
                    arrows={true} autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                            {jobPreferences.map((item, index) => (
                                <div className="col-md-12">
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-services style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src={process.env.PUBLIC_URL+'/images/15471.png'} />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3><a href={process.env.PUBLIC_URL + '/services'}>{item.name}</a></h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Price: <span className='text-danger' style={{fontWeight:'bold'}}>&#8377;{item.platform_charge}/-</span></p>
                                            </div>
                                            {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey" 
                                            href={process.env.PUBLIC_URL + '/services_details'}>read more!</a> */}
                                        </div>
                                    </div>{/* featured-imagebox end */}
                                </div>
                            ))}
                    </Slider>
                    {/* Slider end */}
                </div>
            </section>
            {/* services-section end */}


            {/* about-section */}
            <section className="ttm-row about-section bg-layer-equal-height clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="pb-70 pb-md-50 pr-60 pr-md-0">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Choose <span className="text-theme-SkinColor">any</span></h3>
                                        <h2 className="title">Job Seeker!</h2>
                                    </div>
                                    <div className="title-desc"><p>Our mission is to connect people to jobs at scale. In order for businesses to
                                         hire the best talent, people deserve to easily find a job they want to do. Start applying to the 5,000+
                                          companies in our network.</p></div>
                                </div>{/* section title end */}
                                <div className="mb-md-40 mb-30">
                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">You can track the status of your applications in the candidate portal.</div></li>
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">We automatically complete profile with your professional experience</div></li>
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Other questions & have already applied through the HireCo platform</div></li>
                                    </ul>
                                </div>
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" href={process.env.PUBLIC_URL + '/job_list'}>Find A Jobs!</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* col-img-img-one */}
                            <div className="col-bg-img-one ttm-col-bgimage-yes ttm-bg"  >
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url(images/hero-969x686.jpg)' }}></div>
                                <div className="layer-content"></div>
                                <img src={process.env.PUBLIC_URL+"/images/hero-969x686.jpg"} className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>{/* col-img-bg-img-one end */}
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-md-6">
                            {/* col-img-img-two */}
                            <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg mt-md-50">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(images/photodune.jpg)' }}></div>
                                <div className="layer-content"></div>
                                <img src={process.env.PUBLIC_URL+"/images/photodune.jpg"} className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>{/* col-img-bg-img-two end */}
                        </div>
                        <div className="col-md-6">
                            <div className="pt-70 pt-md-50 pl-70 pl-md-0">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Choose <span className="text-theme-SkinColor">any</span></h3>
                                        <h2 className="title">Service!</h2>
                                    </div>
                                    <div className="title-desc"><p>Nana has over 11 years experience of Financial, Marketing and IT Solutions 
                                        expertise. Helps business improve their processes, products, services. Serve as the intermediary between businesses.</p></div>
                                </div>{/* section title end */}
                                {/* ttm-progress-bar */}
                                <div className="ttm-progress-bar clearfix">
                                    <h3 className="progressbar-title">Daily Service</h3>
                                    <ProgressBar  rect  percentage="90" />
                                </div>
                                {/* ttm-progress-bar end */}
                                {/* ttm-progress-bar */}
                                <div className="ttm-progress-bar clearfix">
                                    <h3 className="progressbar-title">Monthly Service</h3>
                                    <ProgressBar  rect  percentage="70" />
                                </div>
                                {/* ttm-progress-bar end */}
                                {/* ttm-progress-bar */}
                                <div className="ttm-progress-bar clearfix">
                                    <h3 className="progressbar-title">Contract Job</h3>
                                    <ProgressBar  rect  percentage="80" />
                                </div>{/* ttm-progress-bar end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* about-section */}


            {/* padding_zero-section */}
            <section className="ttm-row padding_zero-section bg-layer bg-layer-equal-height mb-70 mb-md-0 clearfix">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-7 col-md-12">
                            {/* col-img-img-three */}
                            <div className="col-bg-img-three ttm-bg ttm-col-bgimage-yes ttm-left-span z-index-2">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url(images/Business-deal-sale_fizkes_BDC-3.png)' }}></div>
                                <div className="layer-content">
                                </div>
                                <img className="img-fluid col-bg-img-res" src={process.env.PUBLIC_URL+'/images/Business-deal-sale_fizkes_BDC-3.png'} alt="bgimage" />
                            </div>{/* col-img-bg-img-one end */}
                        </div>
                        <div className="col-lg-5">
                            <div className="ttm-bg ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg ttm-right-span spacing-1 z-index-1">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                    <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                </div>
                                <div className="layer-content text-theme-WhiteColor">
                                    {/* section title */}
                                    <div className="section-title">
                                        <div className="title-header">
                                            <h3>How <span className="text-theme-SkinColor">we are! </span></h3>
                                            <h2 className="title">Reliable & Cost Efficient Recruitment Agency</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <div className="ttm-tabs ttm-tab-style-01">
                                        <Tabs>
                                            <TabList className="tabs">
                                                <Tab className="tab"><a className="tab-1" tabIndex="0">Our Mision</a></Tab>
                                                <Tab className="tab"><a className="tab-2" tabIndex="0">Our Vision</a></Tab>
                                                <Tab className="tab"><a className="tab-3" tabIndex="0">Core Values</a></Tab>
                                            </TabList> 
                                            <div className="content-tab mb_15">                                
                                                <TabPanel>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-3 col-sm-4">
                                                        <img className="img-fluid alignleft" 
                                                        height="200" width="200" src={process.env.PUBLIC_URL+'/images/mission.jpg'} alt="image" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-9 col-sm-8">
                                                        <div className="pt-15">
                                                            <p>Let's enable the discovery of opportunities by providing consistently superior solutions.
                                                                    To add value & respect individuality and diversity while growing...</p>
                                                            <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">To add value for our clients & candidates</div></li>
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Agency manages the end-to-end process</div></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                </TabPanel>
                                                <TabPanel>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-3 col-sm-4">
                                                        <img className="img-fluid alignleft" height="200" width="200" src={process.env.PUBLIC_URL+'/images/our-vision.jpg'} alt="image" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-9 col-sm-8">
                                                        <div className="pt-15">
                                                            <p>Let's enable the discovery of opportunities by providing consistently superior solutions.
                                                                    To add value & respect individuality and diversity while growing...</p>
                                                            <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">To add value for our clients & candidates</div></li>
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Agency manages the end-to-end process</div></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                </TabPanel>
                                                <TabPanel>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-3 col-sm-4">
                                                        <img className="img-fluid alignleft" height="200" width="200" src={process.env.PUBLIC_URL+'/images/our_values1.jpg'} alt="image" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-9 col-sm-8">
                                                        <div className="pt-15">
                                                            <p>Let's enable the discovery of opportunities by providing consistently superior solutions.
                                                                    To add value & respect individuality and diversity while growing...</p>
                                                            <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">To add value for our clients & candidates</div></li>
                                                                <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Agency manages the end-to-end process</div></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                </TabPanel>
                                            </div>       
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* padding_zero-section end */}
                        
            {/* recruitment-section */}
           <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>how <span className="text-theme-SkinColor"> we do!</span></h3>
                                    <h2 className="title">Recruitment Process</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark mb-15"
                             href={process.env.PUBLIC_URL + '/'}>Get Started</a>
                        </div>
                        <div className="col-lg-6">
                            <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>We Identify Your Needs</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need </p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>Create Recruitment Plan</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Save time and energy by creating the best recruitment plan. To get the word out about the job</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>Recruit Top Candidates</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>You will need to maintain timely communication or they will quickly move on to other opportunities</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>The Brand Job Offering</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10 col-11 m-auto">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg spacing-2 z-index_1" style={{backgroundImage:'url(images/banner-img-8278c.png)',backgroundPosition:'right'}}>
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content">                                      
                                </div>
                            </div>
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/3d35f.png'} alt="single_03" />
                            </div>{/* ttm_single_image-wrapper */}                           
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* recruitment-section end */}

            {/* aboutus-section */}
            <section className="ttm-row about-section bg-img2 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("images/slides/slider-mainbg-001.jpg")','opacity':'0.8!important' }}>
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                <div className="container">
                    {/* row */}
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4 col-md-12">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>More than 50,000 <span className="text-theme-SkinColor">happy members</span></h3>
                                    <h2 className="title">The Most Trusted and Professional Recruitment <span className="text-theme-SkinColor"> Agency</span></h2>
                                </div>
                            </div>{/* section title end */}
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white mt-15" 
                            href={process.env.PUBLIC_URL + '/candidate_list'}>Hiring Now!</a>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                            <div className="pt-lg-30">
                                {/* fid */}
                                <div className="ttm-fid inside ttm-fid-view-topicon style1">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-recruiting"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={2887} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">Happy Members</h3>
                                    </div>
                                </div>{/* fid end */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-8 col-sm-7">
                            <div className="pt-lg-20 pb-30">
                                <p className="text-theme-WhiteColor">We have over 11 years experience of Financial, Musiness Solutions expertise, our goal imize your.</p>
                            </div>
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">Consulting Experience</h3>
                                <ProgressBar rect percentage="90" />
                            </div>
                            {/* ttm-progress-bar end */}
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">Investment Plan</h3>
                                <ProgressBar rect percentage="85" />
                            </div>
                            {/* ttm-progress-bar end */}
                        </div>
                        <div className="col-lg-12">
                            <div className="pt-100 pt-md-0"></div>
                        </div>
                    </div>
                </div>
            </section> 
            {/* aboutus-section end */}


            {/* padding_zero-section */}
            <section className="ttm-row padding_zero-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pt-5 bg-theme-SkinColor mt_100 mt-md-60"></div>
                            <div className="bg-theme-WhiteColor box-shadow pt-md-40 pb-md-30">
                                <div className="row g-0 ttm-vertical_sep">
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-headhunting"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={8705} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Employers</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-technical-support"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={480} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Professional Employees</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-4"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={6260} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">All Members</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-3"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={9774} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Happy Members</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* padding_zero-section end */}

            {/* testimonial-section */}
            <section className="ttm-row testimonial-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Our <span className="text-theme-SkinColor">Members!</span></h3>
                                    <h2 className="title">What Our Member Says!</h2>
                                </div>
                            </div>
                        </div>
                      </div>
                    <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}>
                        <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">It has always been so easy to do work with team member at HireCo. It's an much effortless partnership.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/1fc51.png'} />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>XYZ</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">The national award-winning staffing agency that was built with our trademarked tagline, Expect More, We Do.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/1fc51.png'} />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>ABC</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">This was my first experience working with a recruiter and it has been amazing. I couldn’t have asked for more</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/1fc51.png'} />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>MLM</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">The national award-winning staffing agency that was built with our trademarked tagline, Expect More, We Do.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL+'/images/1fc51.png'} />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>Nana</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                    </Slider>
                </div>
            </section>
            {/* testimonial-section end */}


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 11 years experience oft Business.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/contact_02'}>Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}

            <Footer/>
                        
          </div>
        )
    }
}


export default Home;
