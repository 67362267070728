import React, { useState,useEffect } from 'react';
import Slider from 'react-slick';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';

export const Dashboard = () => {
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [error, setError] = useState(null);
    const [catList,setCatList]=useState([]);
    const [employeeList,setEmployeeList] = useState([]);
    const [employerList,setEmployerList] = useState([]);
    const [employeeListNear,setEmployeeListNear] = useState([]);
    const [employerListNear,setEmployerListNear] = useState([]);
    const [adminPromotion,setAdminPromotion] = useState([]);
    const [locationPromotion,setLocationPromotion] = useState([]);
    const [statePromotion,setStatePromotion] = useState([]);
    const [districtPromotion,setDistrictPromotion] = useState([]);
    const [panPromotion,setPanPromotion] = useState([]);

    const [search,setSearch]=useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const api_url=process.env.REACT_APP_API_URL
    useEffect( () => {
        if(auth){
            getLocation();
            getHomeRunningPromotion();
            fetchCategoryData();
            fetchEmployee();
            fetchEmployer();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    var access_token='';
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }]
    };
    var admin_slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
    };
    var location_slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
    };
    var state_slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
    };
    var district_slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
    };
    var pan_slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
    };
    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            position => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                updateLocation(position.coords.latitude,position.coords.longitude);
                fetchEmployeeNear(position.coords.latitude,position.coords.longitude);
                fetchEmployerNear(position.coords.latitude,position.coords.longitude);
            },
            error => {
                setError(error.message);
            }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };
    const updateLocation = async (lat,long) => {
        let raw = JSON.stringify({"latitude":lat,"longitude":long});
        access_token="Bearer "+auth;
        const result=fetch(api_url + 'user/update_lat_lon', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token,
            },
            body: raw,
            redirect: "follow"
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                // alert(result.message+' successfully!!')
            }else{
                // alert(result.error+"!!")
            }
        })
    }
    const fetchCategoryData = async () => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            const response = await fetch(api_url+'get_category');
        //   if (!response.ok) {
        //     throw new Error('Network response was not ok');
        //   }
            const result = await response.json();
            setCatList(result.category);
        } catch (error) {
            console.error('Error fetching category:', error);
        }
    };
    const fetchEmployee = async () => {
        fetch(api_url+'/user/get_employee'
        )
        .then(response => response.json())
        .then(result => setEmployeeList(result))
        .catch(error => console.log(error));
    }
    const fetchEmployer = async () => {
        fetch(api_url+'/user/get_employer'
        )
        .then(response => response.json())
        .then(result => setEmployerList(result))
        .catch(error => console.log(error));
    }

    const fetchEmployeeNear = async (lat,long) => {
        fetch(api_url+'/user/get_employee?latitude='+lat+'&longitude='+long
        )
        .then(response => response.json())
        .then(result => setEmployeeListNear(result))
        .catch(error => console.log(error));
    }
    const fetchEmployerNear = async (lat,long) => {
        fetch(api_url+'/user/get_employer?latitude='+lat+'&longitude='+long
        )
        .then(response => response.json(lat,long))
        .then(result => setEmployerListNear(result))
        .catch(error => console.log(error));
    }
    const getHomeRunningPromotion = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_running_promotion?type=home', {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setAdminPromotion(result.admin);
                setLocationPromotion(result.location);
                setStatePromotion(result.state);
                setDistrictPromotion(result.district);
                setPanPromotion(result.pan_india);
            }
        })
    }

    const onHandleSearch = () => {
        history.push(process.env.PUBLIC_URL+'/filter/'+search)
      }
    
    return (
        <>
            <div>
                <SideNavbar />
                <div style={{ display: 'flex' }}>
                    <Container>
                        {/* <Typography variant="h4">Welcome to your Dashboard!</Typography> */}
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slide__content--headings ttm-textcolor-white text-center">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <p  data-animation="animate__fadeInDown" className="mb-30 mb-md-0">
                                                        <form id="b_search_Form" className="b_search_Form wrap-form d-block" method="post" action="#" data-mailchimp="true">
                                                        <div className="row row-equal-height">
                                                            <div className="col-lg-8">
                                                            <label>
                                                                <i className="ti ti-search"></i>
                                                                <input type="text" className='form-control' onChange={ (e) => setSearch(e.target.value) } id="filter" placeholder="Job Title or username or city name or skill name or category name"/>
                                                            </label>
                                                            </div>
                                                            <div className="col-lg-3">
                                                            <label>
                                                                <button className="submit ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill 
                                                                ttm-btn-color-skincolor" type="button" onClick={onHandleSearch}>Find Jobs</button>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        </form>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                                        <a className='btn btn-sm text-dark catp2' href={process.env.PUBLIC_URL + '/contract_job'}>Contract Jobs</a>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                                        <a className='btn btn-sm text-dark catp2' href={process.env.PUBLIC_URL + '/promotion'}>Add Promotions</a>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                                        <a className='btn btn-sm text-dark catp2' href={process.env.PUBLIC_URL + '/contact_02'}>Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <Slider className="row slick_slider slick-arrows-style1" {...admin_slick_slider} arrows={true}>
                                {adminPromotion.map((item, index) => (
                                    item.photos.map((item2) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <a href={process.env.PUBLIC_URL + '/promotion_details/'+item._id}>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-promotion">
                                                        <img className="img-fluid ttm-icon_element-size-promotion" src={api_url+item2} />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))))}
                                </Slider>
                            </div>
                        </section>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <div className="title-header">
                                                <h3>Job <span className="text-theme-SkinColor">Categories!</span><a href='/category'><i className='fa fa-angle-right mt-3 alldata'></i></a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} arrows={true}>
                                {catList.map((item, index) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content style1">
                                            <a href={process.env.PUBLIC_URL + '/job_category/'+item._id}>
                                                <div className="ttm-box-view-overlay">
                                                    {/* finance-hover */}
                                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/backblue.gif")' }}></div>
                                                        <div className="layer-content"></div>
                                                    </div>{/* finance-hover end */}
                                                </div>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                                        <img className="img-fluid" src={api_url+item.image} style={{width:'78px',height:'78px',borderRadius:'50%'}} />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    {/* <div className="featured-desc">
                                                        <p>85 Jobs<br/></p>
                                                    </div> */}
                                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark btn btn-md btn-primary p-2 text-white" 
                                                    href={process.env.PUBLIC_URL + '/job_category/'+item._id}>View</a>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))}
                                </Slider>
                            </div>
                        </section>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <Slider className="row slick_slider slick-arrows-style1" {...location_slick_slider} arrows={true}>
                                {locationPromotion.map((item, index) => (
                                    item.photos.map((item2) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <a href={process.env.PUBLIC_URL + '/promotion_details/'+item._id}>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-promotion">
                                                        <img className="img-fluid ttm-icon_element-size-promotion" src={api_url+item2} />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))))}
                                </Slider>
                            </div>
                        </section>
                        <div className="ttm-row sidebar job-sidebar clearfix">
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div><a className='alldata2' href='/employees_list_near'>view all</a></div>
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">Employees near me</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                employeeListNear.employee && employeeListNear.employee.length> 0 ?
                                                employeeListNear.employee.map((item, index) => {
                                                    if(index<7){
                                                        return(
                                                        <div className="col-lg-12 col-md-12">
                                                            {/* featured-imagebox */}
                                                            <div className="featured-imagebox featured-imagebox-employer">
                                                                <div className="featured-thumbnail">
                                                                    <img src={api_url+item.profile} />
                                                                </div>
                                                                <div className="featured-content">
                                                                    <div className="featured-title">
                                                                        <h3>{item.name}</h3>
                                                                    </div>
                                                                    <div className="featured-bottom">
                                                                        <div className="job-meta">
                                                                            <span className='skills-list'>Skills: {item.userSkills.map((item2, index) => (
                                                                                index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                                                ))}
                                                                            </span>
                                                                            <span>Rating<br></br>
                                                                                <div className="star-ratings">
                                                                                    <ul className="rating">
                                                                                        {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                        <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                        ))}
                                                                                        <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                                    </ul>
                                                                                </div>

                                                                            </span>
                                                                        </div>
                                                                        {auth?(<>
                                                                        <div className="view-block">
                                                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                            href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                                        </div>
                                                                        </>):''}
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-imagebox end */}
                                                        </div>
                                                        )
                                                    }
                                                }
                                                )
                                                :''
                                            }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <Slider className="row slick_slider slick-arrows-style1" {...state_slick_slider} arrows={true}>
                                {statePromotion.map((item, index) => (
                                    item.photos.map((item2) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <a href={process.env.PUBLIC_URL + '/promotion_details/'+item._id}>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-promotion">
                                                        <img className="img-fluid ttm-icon_element-size-promotion" src={api_url+item2} />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))))}
                                </Slider>
                            </div>
                        </section>
                        <div className="ttm-row sidebar job-sidebar clearfix">
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div><a className='alldata2' href='employees_list'>view all</a></div>
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">All Employees</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                employeeList.employee && employeeList.employee.length> 0 ?
                                                employeeList.employee.map((item, index) => {
                                                    if(index<8){
                                                        return(
                                                        <div className="col-lg-12 col-md-12">
                                                            {/* featured-imagebox */}
                                                            <div className="featured-imagebox featured-imagebox-employer">
                                                                <div className="featured-thumbnail">
                                                                    <img src={api_url+item.profile} />
                                                                </div>
                                                                <div className="featured-content">
                                                                    <div className="featured-title">
                                                                        <h3>{item.name}</h3>
                                                                    </div>
                                                                    <div className="featured-bottom">
                                                                        <div className="job-meta">
                                                                            <span className='skills-list'>Skills: {item.userSkills.map((item2, index) => (
                                                                                index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                                                ))}
                                                                            </span>
                                                                            <span>Rating<br></br>
                                                                                <div className="star-ratings">
                                                                                    <ul className="rating">
                                                                                        {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                        <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                        ))}
                                                                                        <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                                    </ul>
                                                                                </div>

                                                                            </span>
                                                                        </div>
                                                                        {auth?(<>
                                                                        <div className="view-block">
                                                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                            href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                                        </div>
                                                                        </>):''}
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-imagebox end */}
                                                        </div>
                                                        )
                                                    }
                                                }
                                                )
                                                :''
                                            }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <Slider className="row slick_slider slick-arrows-style1" {...district_slick_slider} arrows={true}>
                                {districtPromotion.map((item, index) => (
                                    item.photos.map((item2) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <a href={process.env.PUBLIC_URL + '/promotion_details/'+item._id}>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-promotion">
                                                        <img className="img-fluid ttm-icon_element-size-promotion" src={api_url+item2} />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))))}
                                </Slider>
                            </div>
                        </section>
                        <div className="sidebar job-sidebar clearfix" style={{marginBottom:'120px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div><a className='alldata2' href='/employers_list_near'>view all</a></div>
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">Employers near me</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                employerListNear.employer && employerListNear.employer.length> 0 ?
                                                employerListNear.employer.map((item, index) => {
                                                    if(index<7){
                                                        return (
                                                            <div className="col-lg-12 col-md-12">
                                                                {/* featured-imagebox */}
                                                                <div className="featured-imagebox featured-imagebox-employer">
                                                                    <div className="featured-thumbnail">
                                                                        <img src={api_url+item.profile} />
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="featured-title">
                                                                            <h3>{item.name}</h3>
                                                                        </div>
                                                                        <div className="featured-bottom">
                                                                            <div className="job-meta">
                                                                                <span>Profession Name :   {item.profession_name}</span>
                                                                                <span>Rating<br></br>
                                                                                    <div className="star-ratings">
                                                                                        <ul className="rating">
                                                                                            {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                            <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                            ))}
                                                                                            <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                                        </ul>
                                                                                    </div>

                                                                                </span>
                                                                            </div>
                                                                            {auth?(<>
                                                                            <div className="view-block">
                                                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                                href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                                            </div>
                                                                            </>):''}
                                                                        </div>
                                                                    </div>
                                                                </div>{/* featured-imagebox end */}
                                                            </div>
                                                        )
                                                    }
                                                }
                                                )
                                                :''
                                            }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <Slider className="row slick_slider slick-arrows-style1" {...pan_slick_slider} arrows={true}>
                                {panPromotion.map((item, index) => (
                                    item.photos.map((item2) => (
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <a href={process.env.PUBLIC_URL + '/promotion_details/'+item._id}>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-promotion">
                                                        <img className="img-fluid ttm-icon_element-size-promotion" src={api_url+item2} />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))))}
                                </Slider>
                            </div>
                        </section>
                        <div className="sidebar job-sidebar clearfix" style={{marginBottom:'120px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div><a className='alldata2' href='/employers_list'>view all</a></div>
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">All Employers</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                employerList.employer && employerList.employer.length> 0 ?
                                                employerList.employer.map((item, index) => {
                                                    if(index<8){
                                                        return (
                                                            <div className="col-lg-12 col-md-12">
                                                                {/* featured-imagebox */}
                                                                <div className="featured-imagebox featured-imagebox-employer">
                                                                    <div className="featured-thumbnail">
                                                                        <img src={api_url+item.profile} />
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="featured-title">
                                                                            <h3>{item.name}</h3>
                                                                        </div>
                                                                        <div className="featured-bottom">
                                                                            <div className="job-meta">
                                                                                <span>Profession Name :   {item.profession_name}</span>
                                                                                <span>Rating<br></br>
                                                                                    <div className="star-ratings">
                                                                                        <ul className="rating">
                                                                                            {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                            <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                            ))}
                                                                                            <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                                        </ul>
                                                                                    </div>

                                                                                </span>
                                                                            </div>
                                                                            {auth?(<>
                                                                            <div className="view-block">
                                                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                                href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                                            </div>
                                                                            </>):''}
                                                                        </div>
                                                                    </div>
                                                                </div>{/* featured-imagebox end */}
                                                            </div>
                                                        )
                                                    }
                                                }
                                                )
                                                :''
                                            }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default Dashboard;