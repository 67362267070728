import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';

export const AllNearEmployers = () => {
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [error, setError] = useState(null);
    const [employerListNear,setEmployerListNear] = useState([]);
    const [search,setSearch]=useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const api_url=process.env.REACT_APP_API_URL
    useEffect( () => {
        if(auth){
            getLocation();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            position => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                fetchEmployerNear(position.coords.latitude,position.coords.longitude);
            },
            error => {
                setError(error.message);
            }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };
    const fetchEmployerNear = async (lat,long) => {
        fetch(api_url+'/user/get_employer?latitude='+lat+'&longitude='+long
        )
        .then(response => response.json(lat,long))
        .then(result => setEmployerListNear(result))
        .catch(error => console.log(error));
    }

    const onHandleSearch = () => {
        history.push(process.env.PUBLIC_URL+'/filter/'+search)
      }
    
    return (
        <>
            <div>
                <SideNavbar />
                <div style={{ display: 'flex' }}>
                    <Container>
                        {/* <Typography variant="h4">Welcome to your Dashboard!</Typography> */}
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slide__content--headings ttm-textcolor-white text-center">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <p  data-animation="animate__fadeInDown" className="mb-30 mb-md-0">
                                                        <form id="b_search_Form" className="b_search_Form wrap-form d-block" method="post" action="#" data-mailchimp="true">
                                                        <div className="row row-equal-height">
                                                            <div className="col-lg-8">
                                                            <label>
                                                                <i className="ti ti-search"></i>
                                                                <input type="text" className='form-control' onChange={ (e) => setSearch(e.target.value) } id="filter" placeholder="Job Title or username or city name or skill name or category name"/>
                                                            </label>
                                                            </div>
                                                            <div className="col-lg-3">
                                                            <label>
                                                                <button className="submit ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill 
                                                                ttm-btn-color-skincolor" type="button" onClick={onHandleSearch}>Find Jobs</button>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        </form>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="sidebar job-sidebar clearfix" style={{marginBottom:'120px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12 content-area">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                                    <span className="showing-result">All Employers near me</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                employerListNear.employer && employerListNear.employer.length> 0 ?
                                                employerListNear.employer.map((item, index) => {
                                                    return (
                                                        <div className="col-lg-12 col-md-12">
                                                            {/* featured-imagebox */}
                                                            <div className="featured-imagebox featured-imagebox-employer">
                                                                <div className="featured-thumbnail">
                                                                    <img src={api_url+item.profile} />
                                                                </div>
                                                                <div className="featured-content">
                                                                    <div className="featured-title">
                                                                        <h3>{item.name}</h3>
                                                                    </div>
                                                                    <div className="featured-bottom">
                                                                        <div className="job-meta">
                                                                            <span>Profession Name :   {item.profession_name}</span>
                                                                            <span>Rating<br></br>
                                                                                <div className="star-ratings">
                                                                                    <ul className="rating">
                                                                                        {[1, 2, 3, 4, 5].map((valuess) => (
                                                                                        <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                                        ))}
                                                                                        <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                                    </ul>
                                                                                </div>

                                                                            </span>
                                                                        </div>
                                                                        {auth?(<>
                                                                        <div className="view-block">
                                                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                                            href={process.env.PUBLIC_URL + '/member_profile/'+item._id}>view Profile</a>
                                                                        </div>
                                                                        </>):''}
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-imagebox end */}
                                                        </div>
                                                    )
                                                }
                                                )
                                                :''
                                            }
                                        </div>{/* row end */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default AllNearEmployers;