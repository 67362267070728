import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';

import About_us from './pages/About_us';
import Services from './pages/Services';
import Contact_02 from './pages/Contact_02';
import Job_list from './pages/Job_list';
import Job_details from './pages/Job_details';
import Employers_list from './pages/Employers_list';
import Employers_details from './pages/Employers_details';
import Employees_list from './pages/Employees_list';
import Employees_details from './pages/Employees_details';
import Login from './pages/Login';
import Register from './pages/Register';
import Otp from './pages/Otp';


import ScrollToTop from './components/layout/Gotop';
import Category_Result from './pages/Category_Result';
import Search from './pages/Search';
import GoogleLoginButton from './pages/GoogleLoginButton';
import ResetPassword from './pages/ResetPassword';
import VerifyOtp from './pages/VerifyOtp';
import ChangePassword from './pages/ChangePassword';
import Congrats from './pages/Congrats';
import MemberCode from './pages/MemberCode';
import ChooseLanguage from './pages/ChooseLanguage';
import ChooseProfile from './pages/ChooseProfile';
import UpdateProfile from './pages/UpdateProfile';
import ProfileUpdate from './pages/ProfileUpdate';
import ChooseJobPlan from './pages/ChooseJobPlan';
import MemberProfile from './pages/MemberProfile';
import Dashboard from './pages/Dashboard';
import MyCart from './pages/MyCart';
import MyProfile from './pages/MyProfile';
import JobCategory from './pages/JobCategory';
import Category from './pages/Category';
import JobRequests from './pages/JobRequests';
import RecentActivities from './pages/RecentActivities';
import ProfileCategory from './pages/ProfileCategory';
import MembershipHistory from './pages/MembershipHistory';
import Contact_01 from './pages/Contact_01';
import { Filter } from '@material-ui/icons';
import FilterD from './pages/FilterD';
import ContractJob from './pages/ContractJob';
import Promotion from './pages/Promotion';
import AllPromotions from './pages/AllPromotions';
import PromotionDetails from './pages/PromotionDetails';
import RechargePay from './pages/RechargePay';
import Profession from './pages/Profession';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermConditions from './pages/TermConditions';
import AllNearEmployers from './pages/AllNearEmployers';
import AllNearEmployees from './pages/AllNearEmployees';
import ImageUploadAndCrop from './pages/ImageUploadAndCrop';
import CancellationPolicy from './pages/CancellationPolicy';


function App() {
 
  return (
    <div className="page">
      <Router> 
        
        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={ Home } />

        <Route exact path={`${process.env.PUBLIC_URL + '/About_us'}`} component={ About_us } /> 
        <Route exact path={`${process.env.PUBLIC_URL + '/Services'}`} component={ Services } />
        <Route exact path={`${process.env.PUBLIC_URL + '/member_profile/:id'}`} component={ MemberProfile } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Contact_02'}`} component={ Contact_02 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/del_request'}`} component={ Contact_01 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Job_list'}`} component={ Job_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Job_list/:id'}`} component={ Job_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/cat_result/:id'}`} component={ Category_Result } />
        <Route exact path={`${process.env.PUBLIC_URL + '/search/:id'}`} component={ Search } />
        <Route exact path={`${process.env.PUBLIC_URL + '/filter/:id'}`} component={ FilterD } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Job_details'}`} component={ Job_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employers_list'}`} component={ Employers_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employers_details'}`} component={ Employers_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employees_list'}`} component={ Employees_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employees_details'}`} component={ Employees_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Login'}`} component={ Login } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Register'}`} component={ Register } />
        <Route exact path={`${process.env.PUBLIC_URL + '/otp_verification'}`} component={ Otp } />
        <Route exact path={`${process.env.PUBLIC_URL + '/reset_password'}`} component={ ResetPassword } />
        <Route exact path={`${process.env.PUBLIC_URL + '/verify_otp'}`} component={ VerifyOtp } />
        <Route exact path={`${process.env.PUBLIC_URL + '/change_password'}`} component={ ChangePassword } />
        <Route exact path={`${process.env.PUBLIC_URL + '/congrats'}`} component={ Congrats } />
        <Route exact path={`${process.env.PUBLIC_URL + '/generate_memcode'}`} component={ MemberCode } />
        <Route exact path={`${process.env.PUBLIC_URL + '/choose_language'}`} component={ ChooseLanguage } />
        <Route exact path={`${process.env.PUBLIC_URL + '/choose_profile'}`} component={ ChooseProfile } />
        <Route exact path={`${process.env.PUBLIC_URL + '/update_profile'}`} component={ UpdateProfile } />
        <Route exact path={`${process.env.PUBLIC_URL + '/profile_update'}`} component={ ProfileUpdate } />
        <Route exact path={`${process.env.PUBLIC_URL + '/job_plan'}`} component={ ChooseJobPlan } />
        <Route exact path={`${process.env.PUBLIC_URL + '/test'}`} component={ GoogleLoginButton } />
        <Route exact path={`${process.env.PUBLIC_URL + '/dashboard'}`} component={ Dashboard } />
        <Route exact path={`${process.env.PUBLIC_URL + '/cart'}`} component={ MyCart } />
        <Route exact path={`${process.env.PUBLIC_URL + '/myprofile'}`} component={ MyProfile } />
        <Route exact path={`${process.env.PUBLIC_URL + '/job_category/:id'}`} component={ JobCategory } />
        <Route exact path={`${process.env.PUBLIC_URL + '/category'}`} component={ Category } />
        <Route exact path={`${process.env.PUBLIC_URL + '/job_requests'}`} component={ JobRequests } />
        <Route exact path={`${process.env.PUBLIC_URL + '/recent_activities'}`} component={ RecentActivities } />
        <Route exact path={`${process.env.PUBLIC_URL + '/profile_category'}`} component={ ProfileCategory } />
        <Route exact path={`${process.env.PUBLIC_URL + '/membership_history'}`} component={ MembershipHistory } />
        <Route exact path={`${process.env.PUBLIC_URL + '/contract_job'}`} component={ ContractJob } />
        <Route exact path={`${process.env.PUBLIC_URL + '/promotion'}`} component={ Promotion } />
        <Route exact path={`${process.env.PUBLIC_URL + '/all_promotion'}`} component={ AllPromotions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/promotion_details/:id'}`} component={ PromotionDetails } />
        <Route exact path={`${process.env.PUBLIC_URL + '/pay/:id'}`} component={ RechargePay } />
        <Route exact path={`${process.env.PUBLIC_URL + '/profession_skill'}`} component={ Profession } />
        <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} component={ PrivacyPolicy } />
        <Route exact path={`${process.env.PUBLIC_URL + '/term-conditions'}`} component={ TermConditions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/return-cancellation-policy'}`} component={ CancellationPolicy } />
        <Route exact path={`${process.env.PUBLIC_URL + '/employers_list_near'}`} component={ AllNearEmployers } />
        <Route exact path={`${process.env.PUBLIC_URL + '/employees_list_near'}`} component={ AllNearEmployees } />
        <Route exact path={`${process.env.PUBLIC_URL + '/test2'}`} component={ ImageUploadAndCrop } />






       

        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;


