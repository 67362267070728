import React, { useState } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { useHistory } from 'react-router-dom';

export const Contact_02 = () => {
    const [validated, setValidated] = useState(false)
    const [phone, setPhone] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [query, setQuery] = React.useState('')
    const history = useHistory()
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((phone !== '') && (query !== '') && (email !== '')){
            const formData = new FormData()
            formData.append('phone',phone);
            formData.append('email',email);
            formData.append('query',query);
            const result=fetch(api_url + 'user/send_help_message', {
                method: 'POST',
                body: formData,
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+'!!')
                    history.push(process.env.PUBLIC_URL+'/')
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please fill all required fields!!');
        }
    }
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */} 
                <PageHeader           
                    title="Contact us" 
                    breadcrumb="Contact 02" 
                />
                {/* PageHeader end */}

                {/* padding_zero-section */} 
                <section className="ttm-row padding_zero-section bg-layer-equal-height clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row g-0">
                            <div className="col-lg-5">
                                {/* col-img-img-six */}
                                <div className="ttm-bg ttm-col-bgimage-yes col-bg-img-seven ttm-left-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(images/image9c5e.png)' }}></div>
                                    <div className="layer-content">
                                    </div>
                                    <img className="img-fluid col-bg-img-res" src={process.env.PUBLIC_URL+'/images/image9c5e.png'} alt="bgimage" />
                                </div>{/* col-img-bg-img-six end */}
                            </div>
                            <div className="col-lg-7">
                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor h-auto p-40 p-lg-30 mt-lg-30 mt-50 mb-50">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h3>Let’s Get <span className="text-theme-SkinColor">in Touch</span></h3>
                                                <h2 className="title">Request a Callback</h2>
                                            </div>
                                        </div>{/* section title end */} 
                                        <form id="contact_form" className="contact_form wrap-form">
                                            <div className="row ttm-boxes-spacing-10px">
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="email" type="email" placeholder="email here" required="required" onChange={ (e) => setEmail(e.target.value)} />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="phone" type="number" placeholder="phone number here" required="required" onChange={ (e) => setPhone(e.target.value)} />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <textarea name="message" rows="5" placeholder="Message here" required="required" onChange={ (e) => setQuery(e.target.value)}></textarea>
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" type="button" onClick={handleSubmit}>send a message!</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>
                {/* padding_zero-section end */} 

                {/* map-section */} 
                <section>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117223.77996815203!2d85.32132625000001!3d23.343204800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e104aa5db7dd%3A0xdc09d49d6899f43e!2sRanchi%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1702473520403!5m2!1sen!2sin" width="100%" height="530"></iframe>
                </section>
                {/* map-section end */} 

                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 11 years experience oft Business.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                    href={process.env.PUBLIC_URL + '/del_request'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                <Footer/>

            </div>
        )
}


export default Contact_02;