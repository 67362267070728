import React, { Component } from "react";
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';


class Mobile_menu extends Component {
  state = {
    collapse1: false,
    collapseID: '',
    auth: sessionStorage.getItem('user')
  }
  
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }
  
  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }

   
    logout = () => {
        // localStorage.clear()
        sessionStorage.clear();
        alert('Logout Successfully!!');
        this.props.history.push(process.env.PUBLIC_URL+'/login');
    }
  

  render() {
    const { auth } = this.state;
    return (

      <Router>

        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
            <MDBCollapse isOpen={this.state.collapse1} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>Home</MDBDropdownToggle>
                      <MDBDropdownMenu>
                          <MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Home</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                      <MDBDropdownToggle nav caret>Jobs</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/job_list'}>Job Offers</MDBDropdownItem> */}
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/services'}>Services</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>Employers</MDBDropdownToggle>
                      <MDBDropdownMenu>
                          <MDBDropdownItem href={process.env.PUBLIC_URL + '/employers_list'}>All Employers</MDBDropdownItem>
                          {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/employers_details'}>Employers Details</MDBDropdownItem> */}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>Employees</MDBDropdownToggle>
                      <MDBDropdownMenu>
                          <MDBDropdownItem href={process.env.PUBLIC_URL + '/employees_list'}>All Employees</MDBDropdownItem>
                          {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/employees_details'}>Employees Details</MDBDropdownItem> */}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>More</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/about_us'}>About Us</MDBDropdownItem>
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/services'}>Services</MDBDropdownItem> */}
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/services_details'}>Services Details</MDBDropdownItem> */}
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/carrer_01'}>Carrer</MDBDropdownItem> */}
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/login'}>Login</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/register'}>Register</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/error_404'}>Error 404</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/error_405'}>Error 405</MDBDropdownItem> */}
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/contact_02'}>Contact us</MDBDropdownItem>
                        {/* <MDBDropdownItem href={process.env.PUBLIC_URL + '/contact_02'}>Contact us 02</MDBDropdownItem> */}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem> 
                {
                  auth ? (
                      <MDBNavItem>
                        <MDBDropdown>
                          <MDBDropdownToggle nav caret>Account</MDBDropdownToggle>
                          <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/myprofile'}>My Profile</MDBDropdownItem>
                            <MDBDropdownItem onClick={this.logout}>Logout</MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </MDBNavItem>
                    )
                    :''
                }
                {
                  auth === null ? (
                      <MDBNavItem>
                        <MDBDropdown>
                          <MDBDropdownToggle nav caret>Account</MDBDropdownToggle>
                          <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/register'}>Register</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/login'}>Login</MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </MDBNavItem>
                    )
                    :''
                }
                {/* <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>Candidates</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/candidate_list'}>Candidate List</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/candidate_details'}>Candidate Details</MDBDropdownItem>
                    </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem> */}
                {/* <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>Blog</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/blog_classic'}>Blog Classic</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/blog_grid'}>Blog Grid</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/blog_details'}>Blog Details</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem> */}
              </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default withRouter(Mobile_menu);