import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

 const Congrats = () => {
    const [validated, setValidated] = useState(false)
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    useEffect( () => {
        if(auth){
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const handleSubmit = () => {
        history.push(process.env.PUBLIC_URL+'/generate_memcode')
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Accounts Created"
                        breadcrumb="Accounts Created"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/images/web/background.jpg)`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <div style={{textAlign:'center',marginTop:'150px'}}>
                                                            <img src={process.env.PUBLIC_URL+'/images/web/congrats.png'} style={{height:'250px'}}/>
                                                            <h5>You have successfully set up your account</h5>
                                                            </div>
                                                               
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form m-5">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <label className="mb-0">
                                                                            <p className='text-center p-5'>Get Dream Jobs & Services <br></br>and many more at your fingertips</p>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={handleSubmit}>Continue</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default Congrats;