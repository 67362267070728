import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useHistory } from 'react-router-dom';

 const Login = () => {
    const [validated, setValidated] = useState(false)
    const [mobileNumber, setMobileNumber] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState('')
    const [memberId, setMemberId] = React.useState(null)
    const [loginType,setLoginType] = React.useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const clientId="950979873992-blk9lrd59u002r71divku8de4sddad8g.apps.googleusercontent.com"
    useEffect( () => {
        document.title='Nana | Login'
        if(auth){
            history.push(process.env.PUBLIC_URL+'/');
        }
        gapi.load("client:auth2",()=> {
            gapi.auth2.init({clientId:clientId})
        })
    });
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((password !== '') ){
            if(mobileNumber !== null){
                setLoginType('phone');
            }
            if(email !== null){
                setLoginType('email');
            }
            if(memberId !== null){
                setLoginType('member_id');
            }
            if(loginType === ''){
                alert('Please fill all required details');
            }else{
                let raw = JSON.stringify({"login_type":loginType,"email":email,"mobile_number":mobileNumber,"password":password,"member_id":memberId});

                const result=fetch(api_url + 'user/login', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: raw,
                    redirect: "follow"
                })
                .then(response=>response.json())
                .then(result=> {
                    if(result.status === true){
                        sessionStorage.setItem('user',result.access_token);
                        alert(result.message+' successfully!!')
                        history.push(process.env.PUBLIC_URL+'/')
                    }else{
                        alert(result.error+"!!")
                    }
                })
            }
        }else{
            alert('please enter password!!');
        }
    }
    const api_url=process.env.REACT_APP_API_URL
    const checkglogin = (googleId,email,name) => {
        let raw = JSON.stringify({"login_type":'gmail',"email":email,"gmail_token":googleId});

        const result=fetch(api_url + 'user/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: raw,
            redirect: "follow"
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                sessionStorage.setItem('user',result.access_token);
                sessionStorage.setItem('name',name);
                alert(result.message+' successfully!!')
                history.push(process.env.PUBLIC_URL+'/')
            }else{
                alert(result.error+"!!")
            }
        })
    } 

    const handleSuccess = (response) => {
        checkglogin(response.googleId,response.profileObj.email,response.profileObj.name);
    };
    
    const handleFailure = (error) => {
        console.error('Login failed', error);
        // Handle login failure here
        alert(error.error)
    };
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Login"
                        breadcrumb="Login"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="text-center mb-20">
                                                <h3>Login To Your Account</h3>
                                            </div>
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        {/* <Tab className="tab">
                                                            <a>
                                                                <i className="flaticon flaticon-research"></i>
                                                                <span>Candidate</span><h5>Login as a Candidate</h5>
                                                            </a>
                                                        </Tab> */}
                                                        <Tab className="tab">
                                                            <a>
                                                                <i className="flaticon flaticon-job-search"></i>
                                                                <span>Employer/Employee</span><h5>Login as a Employer/Employee</h5>
                                                            </a>
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className='col-lg-12'>
                                                                        <label>
                                                                            <i className="ti ti-mobile"></i>
                                                                            <input type="number" id="textmobile" onChange={(e)=>{ setMobileNumber(e.target.value); setLoginType('phone');}} placeholder="Enter Mobile Number"/>
                                                                        </label>
                                                                    </div>
                                                                    <p className='text-center m-0 p-0'>-- or --</p>
                                                                    <div className="col-lg-12">
                                                                        <label>
                                                                            <i className="ti ti-user"></i>
                                                                            <input type="text" id="textmembercode" onChange={(e)=>{ setMemberId(e.target.value); setLoginType('member_id');}} placeholder="Enter Member ID Code"/>
                                                                        </label>
                                                                    </div>
                                                                    <p className='text-center m-0 p-0'>-- or --</p>
                                                                    <div className="col-lg-12">
                                                                        <label>
                                                                            <i className="ti ti-email"></i>
                                                                            <input type="email" id="txtemail" onChange={(e)=>{ setEmail(e.target.value); setLoginType('email');}} placeholder="Email Address" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <label>
                                                                            <i className="ti ti-lock"></i>
                                                                            <input type="password" id="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Password*"/>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={handleSubmit}>Sign in</button>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <label>
                                                                            <div className="forgot-pwd text-center mt-5">
                                                                                <p><a href={process.env.PUBLIC_URL+"/reset_password"} className="text-theme-SkinColor">Forgot Password?</a></p>
                                                                                <p>Don't have account? <a href={process.env.PUBLIC_URL + '/register'} className="text-theme-SkinColor">Sign Up here</a></p>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                            <div className="login-social-buttons">
                                                <div className="row">
                                                    <div className="col-md-6 mx-auto">
                                                        <GoogleLogin
                                                            clientId="950979873992-blk9lrd59u002r71divku8de4sddad8g.apps.googleusercontent.com"
                                                            buttonText="Login with Google"
                                                            onSuccess={handleSuccess}
                                                            onFailure={handleFailure}
                                                            cookiePolicy={'single_host_origin'}
                                                            className='social-account-button w-100 google-button'
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default Login;