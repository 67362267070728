import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


export const PromotionDetails = () => {
    const [recentActivities,setRecentActivities]=useState([]);
    const [profession_name,setProfession_name]=useState('');
    const [profession_experience,setProfession_experience]=useState('');
    const [profession_description,setProfession_description]=useState('');
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    const {id}=useParams();
    useEffect( ()=> {
        if(auth){
            promotionView(id);
            fetchPromotiondetails(id);
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const fetchPromotiondetails = async (id) => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            var result = await fetch(api_url+'user/get_running_promotion?promotion_id='+id, {
                method: 'get',
                headers: {
                    "Authorization": access_token,
                }
            })
            .then(response=>{ return response.json() })
            .then(result=> {
                if(result.status === true){
                    setRecentActivities(result.promotion);
                    setProfession_name(result.promotion.user_id.profession_name);
                    setProfession_experience(result.promotion.user_id.profession_experience);
                    setProfession_description(result.promotion.user_id.profession_description);

                }else{
                    alert(result.error+"!!")
                    history.push(process.env.PUBLIC_URL+'/');
                }
            })
        } catch (error) {
            console.error('Error fetching Recent Activities:', error);
        }
    };
    var slick_slider = {
        dots: true,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    const promotionView = async (id) => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            let raw = JSON.stringify({"promotion_id":id});
            var result = await fetch(api_url+'user/add_view_count', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>{ return response.json() })
        } catch (error) {
            console.error('Error Promotion Views', error);
        }
    };
    const promotionLike = async (id) => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            let raw = JSON.stringify({"promotion_id":id});
            var result = await fetch(api_url+'user/like_promotion', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>{ return response.json() })
            .then(result=> {
                if(result.status === true){
                    alert(result.message);
                    history.push(process.env.PUBLIC_URL+'/all_promotion');
                }else{
                    alert(result.error+"!!")
                    history.push(process.env.PUBLIC_URL+'/');
                }
            })
        } catch (error) {
            console.error('Error Promotion Like', error);
        }
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Promotion <span className="text-theme-SkinColor">Details</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                {recentActivities ? ( <>
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        <div className='card mb-3' style={{backgroundColor:'rgb(221 221 221 / 52%)'}}>
                                            <div className='card-body'>
                                                {recentActivities.photos && recentActivities.photos.length > 0 ? (
                                                <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} arrows={true}>
                                                    {recentActivities.photos.map((item, index) => (
                                                        <div className="col-lg-12 col-md-10 col-12" key={index}>
                                                            {/* featured-icon-box */}
                                                            <div className="featured-icon-box icon-align-top-content">
                                                                <div className='card'>
                                                                    <div className='card-body'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div>
                                                                                    <img src={api_url+item} style={{width:'100%'}} alt={'Photos'+index}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-icon-box end */}
                                                        </div>
                                                    ))}
                                                </Slider>
                                                ) : ''}
                                                <div className='row mt-2'>
                                                    <div className='col-5 text-center mt-4 p-1 mr-2 mx-auto btn' style={{border:'2px solid #ff8d00',color:'#ff8d00'}} onClick={ () => { promotionLike(recentActivities._id) }}>
                                                        <span className='fa fa-thumbs-up mt-0'>&nbsp;Like Now</span>
                                                    </div>
                                                    <div className='col-5 text-center mt-4 p-1 mx-auto btn' style={{border:'2px solid #ff8d00',color:'#ff8d00'}}>
                                                        <span className='fa fa-eye mt-0'>&nbsp;{recentActivities.likes?recentActivities.likes.length:'0'} Views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card p-2 mb-3'>
                                            <p>Promotion Title</p>
                                            <p style={{fontWeight:'bold'}}>{recentActivities.title}</p>
                                        </div>
                                        <hr style={{height:'3px',color:'#000'}}></hr>
                                        <div className='card p-2 mb-3'>
                                            <p>Promotion Description</p>
                                            <p>{recentActivities.desc}</p>
                                        </div>
                                        <hr style={{height:'3px',color:'#000'}}></hr>
                                        <div className='card p-2 mb-3'>
                                            <p style={{fontWeight:'bold'}}>Profession Name</p>
                                            <p>{profession_name}</p>
                                            <p style={{fontWeight:'bold'}}>Profession Experience</p>
                                            <p>{profession_experience}</p>
                                            <p style={{fontWeight:'bold'}}>Profession Description</p>
                                            <p>{profession_description}</p>
                                        </div>
                                    </div>
                                    </>
                                ): (
                                    <div className="col-10 text-center section-title mx-auto">
                                        <img src={process.env.PUBLIC_URL+'/images/web/Nodata.gif'} style={{width:'300px'}}/>
                                        <h5>No Data Found</h5>
                                        <div>Promotions Details will be displayed here</div>
                                    </div>
                                )}
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default PromotionDetails;