import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import { Banner2 } from '../components/banner/Banner2';
import { Footer } from '../components/layout/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

 const RechargePay = () => {
    const [validated, setValidated] = useState(false)
    const [regId,setRegId] = useState(null)
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    useEffect( () => {
        if(auth){
            document.title='Nana | Payment';
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const {id} = useParams();
    const paynow = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        if((id !== '') ){
            const transid=new Date().getTime();
            let raw = JSON.stringify({"registration_charge_id":id,"payment_mode":'upi',"payment_status":'success',"transaction_id":transid});
            var result = await fetch(api_url+'user/make_registration_payment', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(result=>result.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!')
                    history.push(process.env.PUBLIC_URL+'/');
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please choose payment method!!');
        }
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                    <Banner2/>
                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2" style={{backgroundImage:`url('images/web/plan_header.png')`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className='col-12'>
                                                                        <p>Choose your payment method</p>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className={`w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white ${regId === 'net_banking' ? 'planbtn' : ''}`} 
                                                                             onClick={ () => { setRegId('net_banking'); }} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>NET BANKING</span>
                                                                             </div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className={`w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white ${regId === 'debit_card' ? 'planbtn' : ''}`} 
                                                                             onClick={ () => { setRegId('debit_card'); }} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>DEBIT CARD</span>
                                                                             </div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 col-lg-6">
                                                                        <label className="mb-0">
                                                                            <div 
                                                                            className={`w-100 btn btn-sm btn-light 
                                                                            ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded 
                                                                            ttm-btn-style-fill ttm-btn-color-skincolor 
                                                                            text-white ${regId === 'upi' ? 'planbtn' : ''}`} 
                                                                             onClick={ () => { setRegId('upi'); }} 
                                                                            >
                                                                                <span style={{float:'left'}} className='btn btn-sm btn-yellow'>UPI</span>
                                                                             </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={paynow}>Continue</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default RechargePay;