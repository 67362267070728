import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const RecentActivities = () => {
    const [recentActivities,setRecentActivities]=useState([]);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            fetchRecentActivities();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const fetchRecentActivities = async () => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            var result = await fetch(api_url+'user/get_recent_activity', {
                method: 'get',
                headers: {
                    "Authorization": access_token,
                }
            })
            result = await result.json()
            setRecentActivities(result.recent_activity);
        } catch (error) {
            console.error('Error fetching Recent Activities:', error);
        }
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Recent <span className="text-theme-SkinColor">Activities</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                {recentActivities?.map((item, index) => ( <>
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        <div className='card mb-3' style={{backgroundColor:'rgb(221 221 221 / 52%)'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-2 historyrec'><i class="fas fa-history"></i></div>
                                                    <div className='col-10'><p style={{fontWeight:'bold'}} className='mb-0'>{item.activity}</p><p style={{fontSize:'14px'}}>On {moment(item.createdAt).format('DD-MM-YYYY HH:mm A')}</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                ))}
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default RecentActivities;