import React,{useState, useEffect} from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useHistory } from 'react-router-dom'



export const Register = () => {
    const [validated, setValidated] = useState(false)
    const [name, setName] = React.useState('')
    const [mobileNumber, setMobileNumber] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    const clientId="229924014058-uoemdbnhobvo84i5itdgdgs2e1uiekvq.apps.googleusercontent.com"
    useEffect( () => {
        document.title='Nana | Register'
        if(auth){
            history.push(process.env.PUBLIC_URL+'/');
        }
        gapi.load("client:auth2",()=> {
            gapi.auth2.init({clientId:clientId})
        })
    });
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((name !== '') && (email !=='') && (mobileNumber !== '') && (password !=='')){
           
            let raw = JSON.stringify({"name":name,"email":email,"mobile_number":mobileNumber,"password":password});

            const result=fetch(api_url + 'user/signup', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    alert('Register successfully!!')
                    history.push(process.env.PUBLIC_URL+'/otp_verification')
                }else{
                    alert(result.error+"!!")
                }
            })
        }
    }
    const api_url=process.env.REACT_APP_API_URL
    const checkglogin = (googleId,email) => {
        let raw = JSON.stringify({"login_type":'gmail',"email":email,"gmail_token":googleId});

        const result=fetch(api_url + 'user/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: raw,
            redirect: "follow"
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                sessionStorage.setItem('user',result.access_token);
                alert(result.message+' successfully!!')
                history.push(process.env.PUBLIC_URL+'/congrats')
            }else{
                alert(result.error+"!!")
            }
        })
    } 

    const handleSuccess = (response) => {
        checkglogin(response.googleId,response.profileObj.email);
    };
    
    const handleFailure = (error) => {
        console.error('Registration failed', error);
        // Handle login failure here
        alert(error.error)
    };
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="register"
                        breadcrumb="register"
                    />
                    {/* PageHeader end */}


                    {/* register */}
                    <div className="ttm-row register-section clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="text-center mb-20">
                                                <h3>Create An Account</h3>
                                            </div>
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <a>
                                                            <i className="flaticon flaticon-job-search"></i>
                                                                <span>Employer/Employee</span><h5>Singup as a Employer/Employee</h5>
                                                            </a>
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">                                
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-user"></i>
                                                                            <input type="text" id="txtname" placeholder="Enter Your Name here..*" onChange={(e) => { setName(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-mobile"></i>
                                                                            <input type="number" id="txtphone" placeholder="Enter Your Mobile No. here..*" onChange={(e) => { setMobileNumber(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-email"></i>
                                                                            <input type="email" id="txtemail" placeholder="Enter Your Email here..*" onChange={(e) => { setEmail(e.target.value) }} required="" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <label>
                                                                            <i className="ti ti-lock"></i>
                                                                            <input type="password" id="password" placeholder="Enter 8 digit Password here..*" onChange={(e) => { setPassword(e.target.value) }} required=""/>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <label className="mt-0">
                                                                            <div className="d-md-flex align-items-center justify-content-between">
                                                                                <div className="cookies-checkbox mt-15">
                                                                                    <div className="d-flex flex-row justify-content-start">
                                                                                        <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" required=""/>
                                                                                        <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                            type="button" onClick={handleSubmit}>NEXT</button>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-6 mx-auto">
                                                                        <div className="mt-15">
                                                                            <p>Already have an account?<a href={process.env.PUBLIC_URL + '/login'} className="text-theme-SkinColor"> Sign in here</a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                            <div className="login-social-buttons">
                                                <div className="row">
                                                    <div className="col-md-6 mx-auto">
                                                        <GoogleLogin
                                                            clientId="229924014058-uoemdbnhobvo84i5itdgdgs2e1uiekvq.apps.googleusercontent.com"
                                                            buttonText="Sign In/Sign Up With Google"
                                                            onSuccess={handleSuccess}
                                                            onFailure={handleFailure}
                                                            cookiePolicy={'single_host_origin'}
                                                            className='social-account-button w-100 google-button'
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* login end */}

                    
                <Footer/>
                            
                </div>
            </>
          ) 
      }

export default Register;