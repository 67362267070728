import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Slider from 'react-slick';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const MembershipHistory = () => {
    const [catList,setCatList]=useState([]);
    const [regCharge,setRegCharge] = useState([]);
    const [regId, setRegId] = useState(null);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            fetchCategoryData();
            getRegCharge();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    const fetchCategoryData = async () => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token="Bearer "+auth;
            const response = await fetch(api_url+'user/get_reg_payment_history',{
                method: 'get',
                headers: {
                    "Authorization": access_token,
                    // Other headers if needed
                },
            });
        //   if (!response.ok) {
        //     throw new Error('Network response was not ok');
        //   }
            const result = await response.json();
            setCatList(result.registration_payment_history);
        } catch (error) {
            console.error('Error fetching Recharge payment history:', error);
        }
    };
    const getRegCharge = async () => {
        const api_url=process.env.REACT_APP_API_URL
        const response = await fetch(api_url+'get_registration_charge');
        const result = await response.json()
        setRegCharge(result.registration_charge);
    }
    const planpurchase = async (regId) => {
        const api_url=process.env.REACT_APP_API_URL
        access_token="Bearer "+auth;
        const response = await fetch(api_url+'user/check_registration_expiry',{
            method: 'get',
            headers: {
                "Authorization": access_token,
                // Other headers if needed
            },
        });
        const result = await response.json();
        if(result.renewal === false){
            alert('Your Plan is not expired yet!!');
        }else{
            if(regId !== ''){
                history.push(process.env.PUBLIC_URL+"pay/"+regId);
            }else{
                alert('something went wrong, try again!!');
            }
        }
    }
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="testimonial-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <div className="title-header">
                                                <h3>Membership <span className="text-theme-SkinColor">History</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} arrows={true}>
                                {catList.map((item, index) => (
                                    <div className="col-lg-12 col-md-10 col-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box icon-align-top-content">
                                            <div className='card' style={{border:'1px solid #ff8d00'}}>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <p style={{fontWeight:'bold'}}>{item.registration_charge_id.year} year validity</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'13px',color:'#fff'}}>&#8377; {item.registration_charge_id.price}/-</button>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontSize:'13px'}}>Purchase date -</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontWeight:'bold',fontSize:'13px'}}>{new Date(item.payment_date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', dateStyle: 'full'})}</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontSize:'13px'}}>Expiry date -</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontWeight:'bold',fontSize:'13px'}}>{new Date(item.expire_on).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', dateStyle: 'full'})}</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontSize:'13px'}}>Renewal Charge -</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p style={{fontWeight:'bold',fontSize:'13px'}}>Rs. {item.registration_charge_id.renewal_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* featured-icon-box end */}
                                    </div>
                                ))}
                                </Slider>
                            </div>
                        </section>
                        <section className="features-section clearfix" style={{marginBottom:'160px',height:'600px',overflowY:'scroll',border:'none'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header mt-3">
                                                <h6> <span className="text-theme-SkinColor">Membership Plans</span></h6>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        {regCharge && regCharge.length >0 ? (
                                            regCharge?.map((item, index) => ( <>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase(item._id) } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>{item.year} year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; {item.price}/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; {item.renewal_price}/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        ))):''}
                                        {/* <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>2 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 450/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 200/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>3 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 300/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 150/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>5 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 1000/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 500/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>6 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 1200/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 600/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>7 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 700/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 300/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>8 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 800/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 400/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>9 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 900/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 450/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mb-2' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'2px solid #ff8d00'}} onClick={ () => { planpurchase() } }>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p style={{fontWeight:'bold'}}>10 year</p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <button className='btn btn-sm' style={{backgroundColor:'#ff8d00',fontSize:'14px',color:'#fff'}}>&#8377; 1000/-</button>
                                                    </div>
                                                    <div className='col-12'>
                                                        <p style={{fontSize:'12px'}}>Renewal Charge <span className='ml-2' style={{fontWeight:'bold',color:'#ff8d00'}}>&#8377; 500/-</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </section>
                        <section className='features-section clearfix'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-10 mx-auto'>
                                        <div className='card plannote'>
                                            <div className='card-body'>
                                                <p><span style={{color:'#ff8d00'}}>Note - </span>After the plan expires, you are required to pay the renewal change based on the selected plan to continue accessing the services of this app.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default MembershipHistory;