import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logo from './Logo'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import SettingsPowerSharpIcon from '@mui/icons-material/SettingsPowerSharp';
import ColorLensSharpIcon from '@mui/icons-material/ColorLensSharp';
import TaskSharpIcon from '@mui/icons-material/TaskSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const [sidebarList,setSidebarList]=useState([{'Dashboard':'/dashboard'}, {'Job Request':'job_requests'}, {'Professional Skill':'profession_skill'}, {'Category':'profile_category'}, {'Profile':'profile_update'}, {'Leaves':''}, {'Contract Job':'contract_job'}, {'Promotions':'all_promotion'}, {'Categories':'/category'}, {'Membership History':'membership_history'}, {'Recent Activities':'recent_activities'}]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClose34 = () => {
      setAnchorEl3(null);
  };

    const handleClose3 = () => {
        setAnchorEl3(null);
        logout();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [profile,setProfile] = useState([])
    const [cartList,setCartList] = useState(0);
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    const api_url=process.env.REACT_APP_API_URL
    const name=sessionStorage.getItem('name')
    useEffect( () => {
        if(auth){
            getProfile();
            getCart();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const getProfile = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_profile', {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setProfile(result.profile_details);
                sessionStorage.setItem('customer_type',result.profile_details.customer_type);
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL+'/login');
            }
        })
    }

    const getCart = async () => {
      access_token=sessionStorage.getItem('user')
      access_token="Bearer "+access_token;
      const result=fetch(api_url + 'user/get_cart', {
          method: 'GET',
          headers: {
              "Authorization": access_token,
          }
      })
      .then(response=>response.json())
      .then(result=> {
          if(result.status === true){
              setCartList(result.cart.length);
          }else{
              alert(result.error+"!!");
              history.push(process.env.PUBLIC_URL+'/login');
          }
      })
  }

    const logout = () => {
      // localStorage.clear()
      sessionStorage.clear();
      alert('Logout Successfully!!');
      history.push(process.env.PUBLIC_URL+'/login');
    }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:'#f57c00'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {profile.name?profile.name:name}<br></br>
            <span style={{fontSize:'12px'}}>Subscribed</span>
          </Typography>
          <Typography noWrap component="div" style={{width:'80%',textAlign:'right'}}>
            <ul style={{display:'flex',flexDirection:'row-reverse'}}>
                <li>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick3}
                    >
                        <img src={profile.profile?process.env.REACT_APP_API_URL+'/'+profile.profile:process.env.PUBLIC_URL+"/images/logo2.png"} height="40" width="40" style={{borderRadius:'50%'}} />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl3}
                        keepMounted
                        open={Boolean(anchorEl3)}
                        onClose={handleClose34}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                    >
                        <MenuItem component={Link} to={`${process.env.PUBLIC_URL + '/myprofile'}`}><AccountCircleIcon />My Account</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleClose3} className='bg-danger text-white'><SettingsPowerSharpIcon />Logout</MenuItem> 
                    </Menu>
                </li>
                <li style={{padding:'5px'}}><a href='' className='text-white'><NotificationsActiveIcon /></a></li>
                <li style={{padding:'5px'}}><a href='' className='text-white'><MarkUnreadChatAltIcon /></a></li>
                <li style={{padding:'5px'}}><a href={`${process.env.PUBLIC_URL + '/cart'}`} className='text-white'><ShoppingCartIcon /><span className='cartCount'>{cartList}</span></a></li>
            </ul>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
            <Logo style={{marginRight:'50px',width:'65px'}} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sidebarList.map((item, index) => ( 
            <>
                <ListItem key={index} disablePadding>
                  {Object.keys(item).map((text, index) => (
                    <ListItemButton component={Link} to={item[text]}>
                        <ListItemIcon>
                        {text === 'Dashboard' ? <ColorLensSharpIcon />: <TaskSharpIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                  ))}
                </ListItem>
            </>
          ))}
        </List>
        <Divider />
        <List>
          {['Setting'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={handleClick2}>
                <ListItemIcon>
                  {index === 1 ? <ColorLensSharpIcon /> : <SettingsSharpIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
                <KeyboardArrowDownSharpIcon />
              </ListItemButton>
              <Menu
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                >
                    <MenuItem onClick={handleClose2} component={Link} to="/myprofile">
                        Profile
                    </MenuItem>
                </Menu>
            </ListItem>
          ))}
        </List>
        <Divider />
        <ListItem key='Logout' disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    <SettingsPowerSharpIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' onClick={logout} />
            </ListItemButton>
        </ListItem>
        {/* <List>
          {['Profile', 'Make Promotion', 'Category'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
                <KeyboardArrowDownSharpIcon />
              </ListItemButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/">
                        Sub-Item 1
                    </MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/">
                        Sub-Item 2
                    </MenuItem>
                </Menu>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
