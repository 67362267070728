import React, { Component } from 'react'

export class BottomFooter extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix" style={{position:'fixed',bottom:'0px',width:'100%'}}>
                <div className="bottom-footer-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <span className="copyright">Copyright © 2023 <a href={process.env.PUBLIC_URL + '/'}>Nana Udyog </a>All rights reserved.
                                </span>
                                <ul className="footer-nav-menu">
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Privacy & Policy</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Terms & Conditions</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                                <div>
                                    <p>Developed BY:<img className='ml-5' src="https://www.brightcodess.com/images/Brightcode-Software-Services-Pvt-Ltd.webp"/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}