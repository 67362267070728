import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';


export const About_us = () => {
    const [validated, setValidated] = useState(false)
    const [privacyPolicies,setPrivacyPolicies] = useState('');
    const api_url=process.env.REACT_APP_API_URL
    useEffect(()=> {
        const result=fetch(api_url+'about_us')
        .then(response => response.json())
        .then(result => setPrivacyPolicies(result.about.about))
        .catch(error => console.log(error));
    },[]);
    function PrivacyPolicyView({ privacyPolicy }) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
          </div>
        );
    }
    return (       
        <div className="site-main">

        <Header/>
        
        {/* PageHeader */} 
        <PageHeader
            title="about us"
            breadcrumb="about"
        />
        {/* PageHeader end */} 


        {/* job-list-section */}
        <section className="ttm-row job-list-section ttm-bg clearfix">
            <div className="container">
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* section title */}
                        <div className="section-title title-style-center_text">
                            <div className="title-header">
                                <h3>our <span className="text-theme-SkinColor">Story!</span></h3>
                                <h2 className="title">Know more about</h2>
                            </div>
                        </div>{/* section title end */}
                    </div>
                </div>{/* row end */}
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <PrivacyPolicyView privacyPolicy={privacyPolicies} />
                        </div>{/* timeline end */}
                    </div>
                </div>{/* row end */}
            </div>
        </section>
        {/* job-list end */}


        {/* google_map */}
        <div id="google_map" className="google_map">
            <div className="map_container clearfix">
                <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117223.77996815203!2d85.32132625000001!3d23.343204800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e104aa5db7dd%3A0xdc09d49d6899f43e!2sRanchi%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1702473520403!5m2!1sen!2sin" width="100%" height="530"></iframe>
                </div>
            </div>
        </div>
        {/* google_map end */}
        

        {/* action-section */}
        <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="d-md-flex align-items-center justify-content-between">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-content style2">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                        <i className="flaticon flaticon-recruitment-5"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Incredible Recruitment & Staffing Agency</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>We have over 11 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                            href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* action-section end */}


        <Footer/>
                    
        </div>
    )
}
export default About_us;