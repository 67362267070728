import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

 const ChooseProfile = () => {
    const [validated, setValidated] = useState(false)
    const [customerType,setCustomerType] = useState('')
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    useEffect( () => {
        if(auth){
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((customerType !== '') ){
            const formData = new FormData()
            formData.append("customer_type",customerType)
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            const result=fetch(api_url + 'user/update_custome_type', {
                method: 'POST',
                headers: {
                    "Authorization": access_token,
                },
                body: formData,
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!')
                    history.push(process.env.PUBLIC_URL+'/generate_memcode')
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please select Profile Category!!');
        }
    }
        return (
            <>
                <div className="site-main">
                    <Header/>
                
                    {/* PageHeader */} 
                    <PageHeader
                        title="Choose Profile Category"
                        breadcrumb="Choose Category"
                    />
                    {/* PageHeader end */}


                    {/* login */}
                    <div className="ttm-row login-section clearfix p-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto">
                                    <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                        <div className="layer-content">
                                            <div className="ttm-tabs ttm-tab-style-02">
                                                <Tabs>
                                                    <TabList className="tabs">
                                                        <Tab className="tab">
                                                            <div style={{textAlign:'center'}}>
                                                                <img src={process.env.PUBLIC_URL+'/images/web/language.png'} style={{height:'100px'}}/>
                                                                <p className='mb-0'>Select the category to get started</p>
                                                            </div>
                                                               
                                                        </Tab>
                                                    </TabList> 
                                                    <div className="content-tab">
                                                        <TabPanel>
                                                            <form id="login_form" className="login_form wrap-form">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <label className="mb-0">
                                                                            <button type='button' className="w-100 btn btn-sm btn-primary ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor text-white" onClick={(e) => setCustomerType(e.target.value)} value="employee" readOnly={true}>I am a Employee(Job Seekers)</button>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <label className="mb-0">
                                                                            <button type='button' className="w-100 btn btn-sm btn-primary ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor text-white" onClick={(e) => setCustomerType(e.target.value)} value="employer" readOnly={true}>I am a Employer</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 mx-auto">
                                                                        <label className="mb-0">
                                                                            <button className="submit w-100 ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={handleSubmit}>NEXT</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </TabPanel>
                                                    </div>       
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/* login end */}

                    
                <Footer/>
            </>
          )
   }

export default ChooseProfile;