import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment';

export const AllPromotions = () => {
    const [recentActivities,setRecentActivities]=useState([]);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            fetchPromotions();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const fetchPromotions = async () => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            var result = await fetch(api_url+'user/get_running_promotion', {
                method: 'get',
                headers: {
                    "Authorization": access_token,
                }
            })
            result = await result.json()
            setRecentActivities(result.promotion);
        } catch (error) {
            console.error('Error fetching Promotions:', error);
        }
    };
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>All <span className="text-theme-SkinColor">Promotions</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                {recentActivities.length > 0 ? ( 
                                    recentActivities?.map((item, index) => ( <>
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        <div className='card mb-3' style={{backgroundColor:'rgb(221 221 221 / 52%)'}}>
                                            <div className='card-body'>
                                                <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} arrows={true}>
                                                    {item.photos.map((item2, index) => (
                                                        <div className="col-lg-12 col-md-10 col-12">
                                                            {/* featured-icon-box */}
                                                            <div className="featured-icon-box icon-align-top-content">
                                                                <div className='card'>
                                                                    <div className='card-body'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div>
                                                                                    <img src={api_url+item2} style={{width:'100%'}}/>
                                                                                    <div class="promote_text">
                                                                                        <span className='fa fa-eye mt-0 mr-2' style={{color:'#ff8d00'}}>&nbsp;{item.view_count.length}</span>
                                                                                        <span className='fa fa-thumbs-up mt-0' style={{color:'#fff'}}>&nbsp;{item.likes.length}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-icon-box end */}
                                                        </div>
                                                    ))}
                                                </Slider>
                                                <div className='col-12 text-center mt-2 p-1' style={{border:'2px solid #ff8d00'}}>
                                                    <a href={process.env.PUBLIC_URL+'/promotion_details/'+item._id}>view more</a>
                                                </div>
                                                <div className='col-12 mt-2 p-1' style={{fontSize:'14px'}}>
                                                    <p className='mb-0'>Promotion Started date - {new Date(item.from_date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', dateStyle: 'full'})}</p>
                                                    <p className='mb-0'>Promotion End date - {new Date(item.to_date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', dateStyle: 'full'})}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )) ): (
                                    <div className="col-10 text-center section-title mx-auto">
                                        <img src={process.env.PUBLIC_URL+'/images/web/Nodata.gif'} style={{width:'300px'}}/>
                                        <h5>No Data Found</h5>
                                        <div>Promotions will be displayed here</div>
                                    </div>
                                )}
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default AllPromotions;