import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Menu = () => {
    const history=useHistory();
    const auth=sessionStorage.getItem('user');
    const logout = () => {
        // localStorage.clear()
        sessionStorage.clear();
        alert('Logout Successfully!!');
        history.push(process.env.PUBLIC_URL+'/login');
    }
    return (
        <Router>
            <nav className="main-menu">         
                <ul className="menu">
                    {/* <li className="mega-menu-item"> */}
                        <a href={process.env.PUBLIC_URL + '/'} style={{fontWeight:'bold'}} className="mega-menu-link">Home</a>
                        {/* <ul className="mega-submenu">
                            <li><a href={process.env.PUBLIC_URL + '/'}>Job Seeker</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/home2'}>Job Consultancy</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/home3'}>Human Resource</a></li>
                        </ul> */}
                    {/* </li> */}
                    <li className="mega-menu-item">
                        <a href={process.env.PUBLIC_URL + '/services'} className="mega-menu-link">Jobs</a>
                        <ul className="mega-submenu">
                            {/* <li><a href={process.env.PUBLIC_URL + '/job_list'}>Job Offers</a></li> */}
                            <li><a href={process.env.PUBLIC_URL + '/services'}>Services</a></li>
                        </ul>
                    </li>
                    <li className="mega-menu-item">
                        <a href={process.env.PUBLIC_URL + '/employers_list'} className="mega-menu-link">Employers</a>
                        <ul className="mega-submenu">
                            <li><a href={process.env.PUBLIC_URL + '/employers_list'}>All Employers </a></li>
                            {/* <li><a href={process.env.PUBLIC_URL + '/employers_details'}>Employers Details</a></li> */}
                        </ul>
                    </li>  
                    <li className="mega-menu-item">
                        <a href={process.env.PUBLIC_URL + '/employees_list'} className="mega-menu-link">Employees</a>
                        <ul className="mega-submenu">
                            <li><a href={process.env.PUBLIC_URL + '/employees_list'}>All Employees</a></li>
                            {/* <li><a href={process.env.PUBLIC_URL + '/employees_details'}>Employees Details</a></li> */}
                        </ul>
                    </li>  
                    <li className="mega-menu-item">
                        <a href='' className="mega-menu-link">More</a>
                        <ul className="mega-submenu">
                            <li><a href={process.env.PUBLIC_URL + '/about_us'}>About Us</a></li>
                            {/* <li><a href={process.env.PUBLIC_URL + '/services'}>Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/services_details'}>Services Details</a></li>                                 */}
                            {/* <li><a href={process.env.PUBLIC_URL + '/carrer_01'}>Carrer</a></li> */}
                            {/* <li><a href={process.env.PUBLIC_URL + '/error_404'}>Error 404</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/error_405'}>Error 405</a></li> */}
                            <li><a href={process.env.PUBLIC_URL + '/contact_02'}>Contact us</a></li>
                        </ul>
                    </li>  
                    {
                        auth ? (
                            <a onClick={logout} className="ml-10 btn btn-sm bg-danger text-white p-2">
                            <i className="ti ti-power-off fa-sm text-theme-LightColor m-1"></i>
                            <span className="mr-2 alert-heading"></span>
                            Logout
                            </a>
                        ) : (
                            <>
                            <a href={process.env.PUBLIC_URL + '/register'} className="ml-10">
                                <i className="far fa-user fa-sm text-theme-DarkColor"></i>
                                <span className="mr-2 alert-heading"></span>
                                Register
                            </a>
                            <a href={process.env.PUBLIC_URL + '/login'} className="ml-10">
                                <i className="ti ti-lock fa-sm text-theme-DarkColor"></i>
                                <span className="mr-2 alert-heading"></span>
                                Login
                            </a>
                            </>
                        )
                    }

                </ul>
            </nav>
        </Router>
    )
}

export default Menu;