import React, { useState, useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import {BottomFooter} from '../components/layout/BottomFooter';
import "../css/switch.css";


export const MyProfile = () => {
    const [profile,setProfile] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = React.useState(null)
    const history = useHistory()
    const auth=sessionStorage.getItem('user')
    var access_token='';
    const api_url=process.env.REACT_APP_API_URL
    const name=sessionStorage.getItem('name')
    const [isChecked, setIsChecked] = useState(false);

    useEffect( () => {
        if(auth){
            getProfile();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const getProfile = async () => {
        const api_url=process.env.REACT_APP_API_URL
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/get_profile', {
            method: 'GET',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                setProfile(result.profile_details);
                if(result.profile_details.open_to_work == true){
                    setIsChecked(true);
                }else{
                    setIsChecked(false);
                }
            }else{
                alert(result.error+"!!")
                history.push(process.env.PUBLIC_URL+'/');
            }
        })
    }
    const toggleSwitch = () => {
        setIsChecked(!isChecked);
        let raw = JSON.stringify({"status":!isChecked});
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        const result=fetch(api_url + 'user/manage_open_to_work', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token,
            },
            body: raw,
            redirect: "follow"
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                alert(result.error+' successfully!!');
            }else{
                alert(result.error+"!!");
            }
        })
    };
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const updateprofileimage = (img) => {
        setImage(img);
        const api_url=process.env.REACT_APP_API_URL
        const formData = new FormData()
        formData.append('profile_image', image)
        // console.log(formData)
        if((image !== null)){
            access_token="Bearer "+auth;
            const result=fetch(api_url + 'user/update_profile_photo', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    // Other headers if needed
                },
                body: formData,
            })
            .then(response=>response.json())
            .then(result=>{
                if(result.status == true){
                    alert('Profile updated successfully!!');
                    handleCloseModal();
                    getProfile();
                  }else{
                      alert(result.error+"!!")
                  }
            });
        }
    }
    return (
        <>
            <div>
                <SideNavbar />
                <div style={{ display: 'flex' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mx-auto">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-10 p-lg-20">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div className="ttm-tabs ttm-tab-style-02">
                                            <Tabs>
                                                <TabList className="tabs">
                                                    <Tab className="tab">
                                                    </Tab>
                                                </TabList> 
                                                <div className="content-tab">
                                                    <TabPanel>
                                                        <div className="card">
                                                            <div className="card_background_img"></div>
                                                            <div className="card_profile_img"><img src={profile.profile === null ? process.env.PUBLIC_URL+'/images/logo.png': process.env.REACT_APP_API_URL+'/'+profile.profile } onClick={handleShowModal}/></div>
                                                            <div className="user_details">
                                                                <h5 className='text-center mt-2'>{profile.name?profile.name:name}</h5>
                                                                <p className='text-center mb-0'>Subscribed</p>
                                                                <p className='text-center mb-0'>Open to Work 
                                                                    <label className="switch ml-4">
                                                                        <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </p>
                                                                <p className='text-center mb-0'>Member ID: {profile.member_id}</p>
                                                                <p className='text-center mt-0 mb-0' style={{color:'#000'}}>Rating</p>
                                                                <div className="star-ratings text-center">
                                                                    <ul className="rating">
                                                                        {[1, 2, 3, 4, 5].map((valuess) => (
                                                                        <li className={valuess <= profile.avg_rating ? 'active':''}><i className={valuess <= profile.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                        ))}
                                                                        {/* <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{profile.liked_by ? profile.liked_by.length : 0}</span></li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                </div>       
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content modalcust">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Profile Image</h5>
                                        <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="card">
                                            <div className="">
                                                <div>
                                                    <label htmlFor="input-file" style={{width:'100%'}}>
                                                        <img src={profile.profile === null ? process.env.PUBLIC_URL+'/images/logo.png': process.env.REACT_APP_API_URL+'/'+profile.profile } onClick={handleShowModal} style={{height:'325px',width:'100%',borderRadius:'12px'}}/>
                                                    </label>
                                                </div>
                                                <input
                                                    type="file"
                                                    id="input-file"
                                                    accept=".jpg, .jpeg, .png"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => { updateprofileimage(e.target.files[0]) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Modal */}
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default MyProfile;