import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const ProfileCategory = () => {
    const [recentActivities,setRecentActivities]=useState([]);
    const [activeButton, setActiveButton] = useState(null);
    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            setActiveButton(sessionStorage.getItem('customer_type'));
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);
    const updatecustomertype = async (customer_type) => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            let raw = JSON.stringify({"customer_type":customer_type});
            var result = await fetch(api_url+'user/update_custome_type', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(result=>result.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!')
                }else{
                    alert(result.error+"!!")
                }
            })
        } catch (error) {
            console.error('Error fetching Recent Activities:', error);
        }
    };
    // Function to handle button click
    const handleButtonClick = (buttonName) => {
        sessionStorage.setItem('customer_type',buttonName);
        setActiveButton(buttonName);
        updatecustomertype(buttonName);
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Update the <span className="text-theme-SkinColor">Category</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        <h6 className='mb-3'>Select the category to get started</h6>
                                        <div className='card' style={{backgroundColor:'rgb(221 221 221 / 52%)'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className={`col-5 historyrec catp mr-1 mx-auto ${activeButton === 'employee' ? 'active' : ''}`} onClick={() => handleButtonClick('employee')}>I am a Employee (Job Seekers)</div>
                                                    <div className={`col-5 historyrec catp mx-auto ${activeButton === 'employer' ? 'active' : ''}`} onClick={() => handleButtonClick('employer')}>I am a Employer</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </section>
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default ProfileCategory;