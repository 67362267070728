import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const GoogleLoginButton = () => {
    const clientId="229924014058-uoemdbnhobvo84i5itdgdgs2e1uiekvq.apps.googleusercontent.com"
    useEffect(()=> {
        gapi.load("client:auth2",()=> {
            gapi.auth2.init({clientId:clientId})
        })
    },[]);
    const history=useHistory()
    const api_url=process.env.REACT_APP_API_URL
    const checkglogin = (googleId,email) => {
        let raw = JSON.stringify({"login_type":'gmail',"email":email,"gmail_token":googleId});

        const result=fetch(api_url + 'user/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: raw,
            redirect: "follow"
        })
        .then(response=>response.json())
        .then(result=> {
            if(result.status === true){
                sessionStorage.setItem('user',result.access_token);
                alert(result.message+' successfully!!')
                history.push(process.env.PUBLIC_URL+'/')
            }else{
                alert(result.error+"!!")
            }
        })
    } 

    const handleSuccess = (response) => {
        checkglogin(response.googleId,response.profileObj.email);
        // console.log('Login successful', response);
        // alert(response.profileObj.email)
        // Handle the user authentication here
      };
    
      const handleFailure = (error) => {
        console.error('Login failed', error);
        // Handle login failure here
        alert(error.error)
      };
  return (
    <GoogleLogin
      clientId="229924014058-uoemdbnhobvo84i5itdgdgs2e1uiekvq.apps.googleusercontent.com"
      buttonText="Login with Google"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      cookiePolicy={'single_host_origin'}
      className='social-account-button w-100 google-button'
    />
  );
};

export default GoogleLoginButton;