import React, { useState,useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { PaginationItem, PaginationLink,} from 'react'
import "../css/custom.css";
import { useHistory, useParams } from 'react-router-dom';


const Job_list = () => {
    const [employerList,setEmployerList] = useState([]);
    const api_url=process.env.REACT_APP_API_URL
    const { id } = useParams();
    useEffect(()=> {
        fetch(api_url+'/user/search?query_for='+id
        )
        .then(response => response.json())
        .then(result => { setEmployerList(result); })
        .catch(error => console.log(error));
    },[]);

    const [search,setSearch]=useState('')

    const history = useHistory()

    const onHandleSearch = () => {
      history.push(process.env.PUBLIC_URL+'/search/'+search)
    }

    const [activePage, setActivePage] = useState(1);
  const totalItems = employerList.users ? employerList.users.length : 0; // Total items in your list
  const itemsPerPage = 10; // Items per page

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employerList.users ? employerList.users.slice(indexOfFirstItem, indexOfLastItem):0;

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    setActivePage(page);
    // You can perform actions here when a page is clicked, like fetching data for that page
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={activePage === i}>
          <PaginationLink onClick={() => handlePageClick(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return paginationItems;
  };

    return (

        <div className="site-main">
            <Header/>
        
            {/* PageHeader */} 
            <PageHeader
                title="job list"
                breadcrumb="Job Category"
                className="pb-65 pb-lg-0"
            />
            {/* PageHeader end */}


            {/* form */}
            <div className="form-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-lg-50">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content">
                                    <form id="search_Form" className="search_Form wrap-form" method="post" action="#" data-mailchimp="true">
                                        <label>
                                            <i className="ti ti-search"></i>
                                            <input type="text" id="filter" onChange={ (e) => setSearch(e.target.value) } placeholder="Job Title or username or city name or skill name or category name"/>
                                        </label>
                                        <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" onClick={onHandleSearch} type="button">Find Jobs</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* form end */}


            <div className="ttm-row sidebar job-sidebar clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12 content-area">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="showing-result-block d-sm-flex align-items-center justify-content-center">
                                        <span className="showing-result">All Jobs Results :-</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    employerList.users && employerList.users.length> 0 ?
                                    currentItems.map((item, index) => 
                                    <div className="col-lg-12 col-md-12">
                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-employer">
                                            <div className="featured-thumbnail">
                                                <img src={api_url+item.profile} />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>{item.name}</h3>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        {item.customer_type === 'employer' ?<span>Profession Name :-   {item.profession_name}</span>:''}
                                                        {item.customer_type === 'employee' ? <span className='skills-list'>Skills:- {item.userSkills.map((item2, index) => (
                                                            index === item.userSkills.length - 1 ? item2.name : item2.name + ", "
                                                            ))} 
                                                        </span>
                                                        :''
                                                        }
                                                        <span>Rating<br></br>
                                                            <div className="star-ratings">
                                                                <ul className="rating">
                                                                    {[1, 2, 3, 4, 5].map((valuess) => (
                                                                    <li className={valuess <= item.avg_rating ? 'active':''}><i className={valuess <= item.avg_rating ? 'fa fa-star bg-yellow':'fa fa-star text-dark'}></i></li>
                                                                    ))}
                                                                    <li className='m-3 mt-0 text-dark'><span className='fa fa-thumbs-up'>{item.liked_by.length}</span></li>
                                                                </ul>
                                                            </div>

                                                        </span>
                                                    </div>
                                                    <div className="view-block">
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                                                        href={process.env.PUBLIC_URL + '/employers_details'}>view Profile</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* featured-imagebox end */}
                                    </div>
                                    )
                                    :''
                                }
                                
                                <div className="col-lg-12">
                                    <div className="job-pagination-block">
                                        <a className="page-nav-link">prev</a>
                                        <a className="page-nav-link current">1</a>
                                        <a className="page-nav-link" href="#">2</a>
                                        <a className="page-nav-link" href="#">3</a>
                                        <a className="page-nav-link" href="#">....</a>
                                        <a className="page-nav-link">next</a>
                                    </div>
                                </div>
                            </div>{/* row end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </div>


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}

            
        <Footer/>
                    
        </div>
        )
}

export default Job_list;