import React, { useState,useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import {BottomFooter} from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Profession = () => {
    const [validated, setValidated] = useState(false)
    const [catList,setCatList] = useState([]);
    const [profileDet,setProfileDet]=useState([]);
    const [category_id,setCategory_id]=useState(null);
    const [profession_name,setProfession_name]=useState(null)
    const [profession_desc,setProfession_desc]=useState(null)
    const [profession_experience,setProfession_experience]=useState(null)
    const [profession_photo,setProfession_photo]=useState([])
    const [showModal, setShowModal] = useState(false);
    const [daily_price,setDaily_price]=useState(0);
    const [monthly_price,setMonthly_price]=useState(0);
    const [skill,setSkill]=useState([]);

    const api_url=process.env.REACT_APP_API_URL
    const auth=sessionStorage.getItem('user')
    const history = useHistory()
    var access_token=''
    useEffect( ()=> {
        if(auth){
            loadCategory();
            getProfile();
        }else{
            history.push(process.env.PUBLIC_URL+'/login');
        }
    },[]);

    const handleShowModal = async (category_id) => {
        setShowModal(true);
        access_token="Bearer "+auth;
        var result = await fetch(api_url+'skill?category_id='+category_id, {
            method: 'get'
        })
        result = await result.json()
        setSkill(result.skill)
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const loadCategory = async () => {
        access_token="Bearer "+auth;
        var result = await fetch(api_url+'get_category', {
            method: 'get',
            headers: {
                "Authorization": access_token,
                // Other headers if needed
            }
        })
        result = await result.json()
        setCatList(result)
    }

    const getProfile = async () => {
        access_token=sessionStorage.getItem('user')
        access_token="Bearer "+access_token;
        var result = await fetch(api_url+'user/get_profile', {
            method: 'get',
            headers: {
                "Authorization": access_token,
            }
        })
        .then(response => { return response.json() })
        .then(result => {
            setProfileDet(result.profile_details);
            setCategory_id(result.profile_details.category._id);
        });
    }

    const prmotionsubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        const formData = new FormData()
        formData.append('category_id', category_id)
        formData.append('profession_name', profession_name)
        formData.append('profession_experience', profession_experience)
        formData.append('profession_photo', profession_photo)

        access_token="Bearer "+auth;
        const result=fetch(api_url + 'user/update_profession', {
            method: 'post',
            headers: {
                "Authorization": access_token,
                // Other headers if needed
            },
            body: formData,
        })
        .then(response=>response.json())
        .then(result=>{
            if(result.status == true){
                alert(result.message+' successfully!!')
                getProfile();
            }else{
                alert(result.error+"!!")
            }
        });
    }
    const handleSubmit = (event) => {
        // console.log(formData)
        if((profession_desc !== null) && (profession_name !== null)){
            prmotionsubmit(event);
        }else{
            alert('Please fill all required fields!!');
        }
    }
    const delskill = async (skill_id) => {
        try {
            const api_url=process.env.REACT_APP_API_URL
            access_token=sessionStorage.getItem('user')
            access_token="Bearer "+access_token;
            let raw = JSON.stringify({"skill_id":skill_id});
            var result = await fetch(api_url+'user/delete_skill', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(result=>result.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!')
                    getProfile();
                }else{
                    alert(result.error+"!!")
                }
            })
        } catch (error) {
            console.error('Error fetching Professional Skills:', error);
        }
    };
    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{marginBottom:'160px'}}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="title-header">
                                                <h2>Update your <span className="text-theme-SkinColor">Profession</span></h2>
                                            </div>
                                        </div>{/* section title end */}
                                    </div>
                                </div>{/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                    <div className="col-lg-10 col-md-10 col-12 mx-auto">
                                        {/* featured-icon-box */}
                                        <div className='card mb-3' style={{backgroundColor:'rgb(221 221 221 / 52%)',border:'1px solid #f57c00'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <label>Choose your category</label>
                                                        <select className='form-control' required={true} onChange={ (e) => { setCategory_id(e.target.value) }}>
                                                            <option value="">Select Category</option>
                                                            {catList.category?.map((item1,index) => (
                                                                <option value={item1._id} key={item1.name} selected={item1._id === category_id ? true:false}>
                                                                {item1.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-12'>
                                                        <label>Profession Name</label>
                                                        <input type='text' className='form-control' value={profileDet.profession_name} onChange={ (e) => { setProfession_name(e.target.value) }} placeholder='Enter Profession name here'/>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>Profession Experience</label>
                                                        <textarea className='form-control' value={profileDet.profession_experience} onChange={ (e) => { setProfession_experience(e.target.value) }} placeholder='Enter Profession Experience here'/>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>Profession Description</label>
                                                        <textarea className='form-control' value={profileDet.profession_description} onChange={ (e) => { setProfession_desc(e.target.value) }} placeholder='Enter Profession Description here'/>
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <div>
                                                            <label htmlFor="input-file" className='form-control' style={{border:'2px solid #f57c00',color:'#f57c00',fontSize:'14px'}}>Pick Professional Images</label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            id="input-file"
                                                            accept=".jpg, .jpeg, .png"
                                                            style={{ display: "none" }}
                                                            onChange={ (e) => { setProfession_photo(e.target.files[0]) }}
                                                        />
                                                    </div>
                                                    <div className='col-12 mt-2'>
                                                        <label>Select upto 5 matching skills</label>
                                                        <div className='row'>
                                                            {profileDet.skills?.map((item,index) => (<>
                                                            <div className='col-lg-10 col-12 mt-1'>
                                                                <div className='card p-2 mt-1' style={{border:'1px solid #f57c00',fontSize:'14px'}}>
                                                                    <div className='row'>
                                                                        <div className='col-6 mt-1' style={{textAlign:'left',fontWeight:'bold'}}>
                                                                            <span>skill name</span>
                                                                        </div>
                                                                        <div className='col-6 mt-1' style={{textAlign:'right',fontWeight:'bold'}}>
                                                                            <span>{item.skills.name}</span>
                                                                        </div>
                                                                        <div className='col-6 mt-1' style={{textAlign:'left'}}>
                                                                            <span>Daily Price</span>
                                                                        </div>
                                                                        <div className='col-6 mt-1' style={{textAlign:'right'}}>
                                                                            <span>{item.daily_price}</span>
                                                                        </div>
                                                                        <div className='col-6 mt-1' style={{textAlign:'left'}}>
                                                                            <span>Monthly Price</span>
                                                                        </div>
                                                                        <div className='col-6 mt-1' style={{textAlign:'right'}}>
                                                                            <span>{item.monthly_price}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-12 mt-1'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <button className='btn btn-sm btn-danger mt-3 btn-block' onClick={ () => {delskill(item._id); }}>remove</button>
                                                                    </div>
                                                                    <div className='col-12'>
                                                                        <button className='btn btn-sm btn-primary btn-block mt-2' onClick={() => { handleShowModal(profileDet.category._id)}}>edit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='col-4 mt-2'>
                                                        <button type="button" className='btn btn-sm text-white' style={{backgroundColor:'#f57c00'}} onClick={handleSubmit}>Keep Going</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </section>
                        {/* Modal */}
                        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content modalcust">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Enter your daily and monthly amount</h6>
                                        <button type="button" className="btn-close" onClick={handleCloseModal}>X</button>
                                    </div>
                                    <div className="modal-body">
                                        <input type='number' className='form-control' onChange={(e) => { setDaily_price(e.target.value) }} placeholder='Enter your daily amount'/>
                                        <input type='number' className='form-control mt-2' onChange={(e) => { setMonthly_price(e.target.value) }} placeholder='Enter your monthly amount'/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn text-white btn-block" style={{backgroundColor:'#ff8d00'}} onClick={handleSubmit}>
                                            Update Skill
                                        </button>
                                        <button type="button" class="btn btn-danger btn-block mt-2" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Modal */}
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    )
}


export default Profession;