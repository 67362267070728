import React, { useState, useEffect } from 'react';
import SideNavbar from '../components/layout/SideNavbar';
import Container from '@mui/material/Container';
import { BottomFooter } from '../components/layout/BottomFooter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const ContractJob = () => {
    const [recentActivities, setRecentActivities] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [validated, setValidated] = useState(false)
    const [name,setName] = useState(null)
    const [email,setEmail] = useState(null)
    const [phone,setPhone] = useState(null)
    const [fullAddress,setFullAddress]= useState(null)
    const [contractDetails,setContractDetails] = useState(null)
    const auth = sessionStorage.getItem('user');
    const history = useHistory();
    var access_token=''
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (auth) {
            fetchRecentActivities();
        } else {
            history.push(process.env.PUBLIC_URL + '/login');
        }
    }, []);

    const fetchRecentActivities = async () => {
        try {
            const access_token = sessionStorage.getItem('user');
            const response = await fetch(process.env.REACT_APP_API_URL + 'user/get_contract', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                setRecentActivities(result.contract);
            } else {
                throw new Error('Failed to fetch Contract Job');
            }
        } catch (error) {
            console.error('Error fetching fetch Contract Job:', error);
            // You might want to provide feedback to the user here
        }
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        }
        setValidated(true)
        const api_url=process.env.REACT_APP_API_URL
        if((name !== null) || (email !== null) || (phone !== null) || (fullAddress !== null) || (contractDetails !== null) ){
            let raw = JSON.stringify({"name":name,"email":email,"phone":phone,"full_address":fullAddress,"contract_details":contractDetails});
            access_token=sessionStorage.getItem('user');
            access_token="Bearer "+access_token;
            const result=fetch(api_url + 'user/send_contract_request', {
                method: 'POST',
                headers: {
                    "Authorization": access_token,
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow"
            })
            .then(response=>response.json())
            .then(result=> {
                if(result.status === true){
                    alert(result.message+' successfully!!');
                    handleCloseModal();
                    fetchRecentActivities();
                }else{
                    alert(result.error+"!!")
                }
            })
        }else{
            alert('please fill all required fields!!');
        }
    }

    return (
        <>
            <div>
                <SideNavbar />
                <div>
                    <Container>
                        <section className="features-section clearfix" style={{ marginBottom: '160px' }}>
                            <div className="container">
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section title */}
                                        <div className="section-title title-style-center_text">
                                            <div className="">
                                                <h2>
                                                    Contract <span className="text-theme-SkinColor">Job</span>
                                                </h2>
                                            </div>
                                        </div>
                                        {/* section title end */}
                                    </div>
                                </div>
                                {/* row end */}
                                {/* row */}
                                <div className="row mb_10">
                                    <div className="col-10 text-right section-title mx-auto">
                                        <button className="btn btn-md bg-danger text-white" onClick={handleShowModal}>Send Request</button>
                                    </div>
                                    {recentActivities.length > 0 ? (
                                        recentActivities.map((item, index) => (
                                            <div key={index} className="col-lg-10 col-md-10 col-12 mx-auto">
                                                {/* featured-icon-box */}
                                                <div className="card mb-3" style={{ backgroundColor: 'rgb(221 221 221 / 52%)',border:'1px solid #f57c00' }}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p>Name</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p style={{ fontWeight: 'bold' }} className="mb-0">
                                                                    {item.name}
                                                                </p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Phone</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p style={{ fontWeight: 'bold' }} className="mb-0">
                                                                    {item.mobile_number}
                                                                </p>
                                                            </div>
                                                            <div className='col-12'><hr></hr></div>
                                                            <div className="col-12">
                                                                <p>Contract Job Description</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <textarea readOnly={true} className='form-control'>{item.contract_details}</textarea>
                                                            </div>
                                                            <div className="col-12 mt-1">
                                                                <p>My Address</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <textarea readOnly={true} className='form-control'>{item.full_address}</textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-10 text-center section-title mx-auto">
                                            <img src={process.env.PUBLIC_URL+'images/web/Nodata.gif'} style={{width:'300px'}}/>
                                            <h5>No Data Found</h5>
                                            <div>Contract request will be displayed here</div>
                                        </div>
                                    )}
                                </div>
                                {/* row end */}
                            </div>
                        </section>
                        {/* Modal */}
                        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content modalcust">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Enter Details here</h5>
                                        <button type="button" className="btn-close" onClick={handleCloseModal}>X</button>
                                    </div>
                                    <div className="modal-body">
                                        <label>Contract Details</label>
                                        <textarea className='form-control' onChange={(e) => { setContractDetails(e.target.value) }} placeholder='Enter contract details here'></textarea>
                                        <label>Full Address</label>
                                        <textarea className='form-control' onChange={(e) => { setFullAddress(e.target.value) }} placeholder='Enter Full Address here'></textarea>
                                        <label>Your Name</label>
                                        <input type='text' className='form-control' onChange={(e) => { setName(e.target.value) }} placeholder='Enter Name here'/>
                                        <label>Your Phone Number</label>
                                        <input type='text' className='form-control' onChange={(e) => { setPhone(e.target.value) }} placeholder='Enter Phone number here'/>
                                        <label>Your Email Address</label>
                                        <input type='text' className='form-control' onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter Email address here'/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn text-white" style={{backgroundColor:'#ff8d00'}} onClick={handleSubmit}>
                                            Send Request
                                        </button>
                                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Modal */}
                    </Container>
                </div>
                <BottomFooter />
            </div>
        </>
    );
};

export default ContractJob;
